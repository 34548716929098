import { unMasked } from './../../utils/masks';
import { InscricaoModel } from '../../features/Models/inscricao-model';
import Api from '../index'
import ip from 'src/utils/getNetworkIp';




const InscricaoService = {

    post: async (inscricao: InscricaoModel): Promise<any> => {  
        inscricao.cnpjInstituicaoVinculacao = unMasked(inscricao.cnpjInstituicaoVinculacao)
        inscricao.telefoneInstituicaoVinculacao = unMasked(inscricao.telefoneInstituicaoVinculacao)
        inscricao.telefone1 =  unMasked(inscricao.telefone1)
        inscricao.telefone2 =  unMasked(inscricao.telefone2)
        inscricao.telefoneResponsavel = unMasked(inscricao.telefoneResponsavel)
        const body = JSON.stringify(inscricao);
        return await Api.post(`Inscricao`, body).then((res) => { return res })
    },
    put: async(inscricao: InscricaoModel): Promise<any> =>{        
        inscricao.cnpjInstituicaoVinculacao = unMasked(inscricao.cnpjInstituicaoVinculacao)
        inscricao.telefoneInstituicaoVinculacao = unMasked(inscricao.telefoneInstituicaoVinculacao)
        inscricao.telefone1 =  unMasked(inscricao.telefone1)
        inscricao.telefone2 =  unMasked(inscricao.telefone2)    
        inscricao.telefoneResponsavel = unMasked(inscricao.telefoneResponsavel)    
        const body = JSON.stringify(inscricao);
        
        return await Api.put(`Inscricao`, body).then((res) => { return res })
    },

    getByInscricaoID:  (inscricaoID: number): Promise<any> => {
        return  Api.get(`inscricao/getbyinscricaoid?inscricaoID=${inscricaoID}`).then((res) => { return res.data })
    },

    getByInscricaoIDUserID: async  (inscricaoID: number): Promise<any> => {

        let userString = sessionStorage.getItem('usuarioLogado');        
        let user: any = JSON.parse(userString != null ? userString : '{}');        
        if (user && user != '') {
            return await Api.get(`inscricao/getbyinscricaoiduserid?inscricaoID=${inscricaoID}&userID=${user.id}`).then((res) => { return res.data })
        }
        return null;
    },
    getByUserIDConcursoID2: async (concursoID: number): Promise<any> => {
        let userString = sessionStorage.getItem('usuarioLogado');        
        let user: any = JSON.parse(userString != null ? userString : '{}');        
        if (user && user != '') {
            return await Api.get(`Inscricao/getbyuseridconcursoid?concursoID=${concursoID}&userID=${user.id}`).then((res) => { return res.data })
        }
        return null;
    },
    get: async (inscricaoID:number): Promise<any> => {
        const body = JSON.stringify(inscricaoID);
        return await Api.get(`inscricao/getById$inscricaoID=${inscricaoID}`)
    },
    finalizarInscricao: async(inscricaoID:number):  Promise<any> => {        
        return await Api.put(`inscricao/finalizarInscricao?inscricaoID=${inscricaoID}`)
    },
    inscricaoFinazalida: async (inscricaoID:number)  => {      
        return  InscricaoService.getByInscricaoID(inscricaoID)        
       .then(response =>{ return response?.data?.formularioInscricaoCompleto})              
       .catch((err)=> { return false})
     },
     putConfirmEmailResponsavel: async (inscricaoID:number, cpfResponsavel:string) => {
        return await Api.put(`inscricao/putconfirmemailresponsavel?id=${inscricaoID}&cpf=${cpfResponsavel}`).then((response)=> {})
     },
     putAceiteResponsavel: async (inscricaoID:number, aceiteResponavel:boolean) => {         
       return ip.then((ipResponse)=> {                        
        return Api.put(`inscricao/putaceiteresponsavel?inscricaoID=${inscricaoID}&aceiteResponsavel=${aceiteResponavel}&ipAceiteResponsavel=${ipResponse}`).then((response)=> {})
        })
     }
}

export default InscricaoService;