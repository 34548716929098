import React, { useEffect, useState } from 'react'
import { useLocation, Link, useParams } from 'react-router-dom'
import Style from './email-confirmado-responsavel.module.scss'
import { Modal, Button, Spin, message } from 'antd';
import { unMasked } from '../../../utils/cpf';
import EmailConfirmadoComponent from 'src/public/components/Email-Confirmado/email-confirmado-component';
import InscricaoService from 'src/services/Inscricao-Service/inscricao-service';
import ModalidadeDesenhoService from 'src/services/ModalidadeDesenho-Service/modalidadeDesenho-service';
import moment from 'moment';
import DesenhoService from 'src/services/Desenho-Service/desenho-service';
import Spinner from 'src/public/components/Spinner/spinner-component';


const EmailConfirmadoResponsavel: React.FC<any> = () => {

    let location = useLocation();
    const [html, setHtml] = useState<any>(<></>)
    const [inscricao, setInscricao] = useState<any>()
    const [loading, setLoading] = useState<boolean>(false)
    const [ocultar, setOcultar] = useState<boolean>(false)
    const [modalVisivel, setModalVisivel] = useState<boolean>(false)

    const { id } = useParams<any>();

    const [desenhos, setDesenhos] = useState<any>()
    useEffect(() => {
        InscricaoService.getByInscricaoID(id).then((response) => {
            setInscricao(response.data)
            DesenhoService.getByInscricaoID(id).then((response) => {

                setDesenhos(response)
            })
            // .getModalidadeDesenhoByConcursoID(response.data.concursoID).then((res)=> {                
            //      setModalidadesDesenho(res.data)
            // })
        }).catch((err) => message.error("Falha na conexão com o servidor"))
    }, [id])

    const textoAutorizacaoInscricaoPeloReponsavel = <div ><p >
        Prezado(a) {inscricao?.nomeResponsavel},</p>
        <p>{inscricao?.user?.nome} realizou a inscrição no Concurso para criação do Mascote da Educação Fiscal  na(s) modalidade(s): </p>

        {desenhos?.map((item: any, index: number) => {
            return <p className={Style.marginZero} key={index}>

                {item?.modalidadeDesenho?.descricao} - {inscricao?.numeroProtocolo}{item?.modalidadeDesenhoID} às {moment(item?.dataHoraInclusao).format('HH:mm')} do dia {moment(item?.dataHoraInclusao).format('DD/MM/YYYY')} </p>

        })}
        <br />
        <p>Conforme Edital SED/SEFAZ/2022 N. 1/2022, item 5. DOS PARTICIPANTES  [...] "menores de 18 anos deverão
            estar devidamente autorizados ou representados por seus representantes legais."</p>
        <p>Dessa forma se faz necessário <b>A SUA AUTORIZAÇÃO</b> para que a inscrição do participante {inscricao?.user?.nome} cumpra a exigência constante no regulamento do concurso.</p>
        <p>Caso necessite de esclarecimentos, entre em contato com a comissão organizadora do concurso pelos telefones:</p>
        <p>(67) 3389-7801 </p>
        <p>(67) 99691-6700</p>
        <p> pelo e-mail:<a href="mailto:concursos@fazenda.ms.gov.br"> concursos@fazenda.ms.gov.br </a>  </p>
        <p>
            ou acesse o site do concurso:
        </p>
        <a target="_blank" href='https://www.educacaofiscal.ms.gov.br/concurso-de-mascote/'>https://www.educacaofiscal.ms.gov.br/concurso-de-mascote/</a>
    </div>

    function enviarCpf(values: any) {
        let cpfUnmasked = unMasked(values.cpf)

        setLoading(true)

        InscricaoService.putConfirmEmailResponsavel(id, cpfUnmasked)
            .finally(() => {
                setLoading(false)

            })
            .then((res: any) => {
                setHtml(<><span className={Style.textAlignCenter}>E-mail confirmado com sucesso!</span>
                    {/* <Button
                        className={`${Style.textAlignCenter} ${Style.buttonAutorizacao}`}

                        type='primary' onClick={() => {
                            setModalVisivel(true)
                        }}>
                        Ler texto para
                        autorização
                        da inscrição do menor
                    </Button> */}
                    <div className={Style.textResponsavel} >
                        <p>Prezado(a) <b className={Style.nomes}>{inscricao?.nomeResponsavel}</b>,</p>
                        <p><b className={Style.nomes}>{inscricao?.user?.nome}</b> realizou a inscrição no Concurso para criação do Mascote da Educação Fiscal  na(s) modalidade(s): </p>

                        {desenhos?.map((item: any, index: number) => {
                            return <p className={Style.marginZero} key={index}>
                                {item?.modalidadeDesenho?.descricao} - {inscricao?.numeroProtocolo}{item?.modalidadeDesenhoID} às {moment(item?.dataHoraInclusao).format('HH:mm')} do dia {moment(item?.dataHoraInclusao).format('DD/MM/YYYY')} </p>

                        })}
                        <br />
                        <p>Conforme Edital SED/SEFAZ/2022 N. 1/2022, item 5. DOS PARTICIPANTES  [...] "menores de 18 anos deverão
                            estar devidamente autorizados ou representados por seus representantes legais."</p>
                        <p>Dessa forma se faz necessário <b>A SUA AUTORIZAÇÃO</b> para que a inscrição do participante <b className={Style.nomes}>{inscricao?.user?.nome}</b> cumpra a exigência constante no regulamento do concurso.</p>
                        <p>Caso necessite de esclarecimentos, entre em contato com a comissão organizadora do concurso pelos telefones:</p>
                        <p>(67) 3389-7801 </p>
                        <p>(67) 99691-6700</p>
                        <p> pelo e-mail:<a className={Style.link} href="mailto:concursos@fazenda.ms.gov.br"> concursos@fazenda.ms.gov.br </a>  </p>
                        <p>
                            ou acesse o site do concurso:
                        </p>
                        <a className={Style.link} target="_blank" href='https://www.educacaofiscal.ms.gov.br/concurso-de-mascote/'>https://www.educacaofiscal.ms.gov.br/concurso-de-mascote/</a>

                        <div className={Style.buttons}>
                            <Button className={Style.buttonAceite} onClick={onCancel} type="primary" danger >
                                Não autorizar
                            </Button>
                            <Button className={Style.buttonAceite} onClick={onOk} type="primary" >
                                Autorizar
                            </Button>
                        </div>
                    </div>
                </>)
                setOcultar(true)
            }).catch((error) => {

                if (error.response)
                    setHtml(<><span className={Style.emailConfirmadoDivError}>{error.response.data.error}</span></>);
                else
                    message.error("Falha na conexão com o servidor")

            })
    }
    const onOk = () => {
        setModalVisivel(false)
        setLoading(true)
        InscricaoService.putAceiteResponsavel(id, true)
            .finally(() => setLoading(false))
            .then(() => message.success("Inscrição autorizada"))
            .catch((error) => message.error(error.response.data.error))
            ;
    }
    const onCancel = () => {
        setModalVisivel(false)
        setLoading(true)
        InscricaoService.putAceiteResponsavel(id, false)
            .finally(() => setLoading(false))
            .then(() => message.success("Inscrição não autorizada"))
            .catch((error) => message.error(error.response.data.error))
            ;
    }

    return (
        <>
            <EmailConfirmadoComponent

                loading={loading}
                messageRequest={html}
                methodOnFinish={enviarCpf}
                labelCpf={'Informe seu CPF para confirmar seu e-mail'}
                ocultar={ocultar}

            ></EmailConfirmadoComponent>

            {/* <Modal
                bodyStyle={{ height: '65vh', overflowY: 'scroll' }}
                title={null}
                className={Style.modal}
                visible={modalVisivel}
                okText={"Autorizado"}
                cancelText={"Não Autorizado"}
                maskClosable={false}
                closable={false}
                cancelButtonProps={{ danger: true }}

                onOk={onOk}
                onCancel={onCancel}
            >
                <div className={Style.modalAutorizacao}>
                    <div className={Style.btnCloseModalAutoriazacao} onClick={() => { setModalVisivel(false) }}>x</div>

                    {textoAutorizacaoInscricaoPeloReponsavel}
                </div>

            </Modal> */}

        </>
    )
}
export default EmailConfirmadoResponsavel