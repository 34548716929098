import { Button, Input, Checkbox, message } from 'antd';
import 'antd/dist/antd.css'
import React, { useEffect, useState } from 'react';
import { store } from '../../../features/store';
import TabContent from '../../components/TabContent/tabcontent-component';
import Styles from './inscricao-page.module.scss';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import InscricaoService from '../../../services/Inscricao-Service/inscricao-service';
import Spinner from '../../../public/components/Spinner/spinner-component';
import Style from './protocolo-inscricao-page.module.scss';
import Ass from '../../../assets/AssinaturaLuiz.png';
import Logo from '../../../assets/LogoConcurso.png';
import moment from 'moment';
import Ass2 from '../../../assets/Assinatura2.png'

import axios from 'axios';
import { VerticalAlignBottomOutlined } from '@ant-design/icons';

const ProtocoloInscricaoPage: React.FC<any> = () => {
    const { id } = useParams<Record<string, string | string>>();
    const [inscricao, setInscricao] = useState<any>();
    let [load, setLoad] = useState<boolean>(false);
    const history = useHistory();

    useEffect(() => {
        var usuario = JSON.parse(sessionStorage.getItem('usuarioLogado') || "{}")
        setLoad(true)
        InscricaoService.getByInscricaoID(parseInt(id)).then((res) => {
            setInscricao(res.data)
            if (usuario.id != res.data?.user?.id)
                history.push('/error', 'Usuário logado não tem acesso a essa Inscrição')
        }).finally(() => {
            setLoad(false)
        });

    }, [])

    const donwloadComprovante = () => {
        setLoad(true)
        axios({
            url: `${process.env.REACT_APP_BASEURL_API}/inscricao/imprimircomprovanteinscricao?inscricaoID=${parseInt(id)}`,
            method: 'GET',
            responseType: 'blob',
            headers: { Authorization: 'Bearer ' + sessionStorage.getItem("Token") + "" }
        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `Comprovante_Inscricao_${inscricao.numeroProtocolo}.pdf`);
            document.body.appendChild(link);
            link.click();        
        })
        .finally(()=>{
            setLoad(false)
        })

    }

    return (
        <>
            <Spinner loading={load} />
            <div className={Style.container}>
                <div className={Style.bg}>

                    <div className={Style.content}>
                        <div className={Style.header}>
                            <b>{inscricao?.user?.nome}</b>
                            <strong> {moment(inscricao?.dataHoraInclusao).format('DD/MM/YYYY')}</strong>
                        </div>
                        <div className={Style.corpo}>
                            <p>
                                Você acaba de inscrever-se no concurso para criação do
                                Mascote da Educação Fiscal do Mato Grosso do Sul
                            </p>
                            <p>
                                Agradecemos sua participação e desejamos Boa Sorte!
                            </p>
                        </div>
                        <div className={Style.assinaturaBox}>
                            <img src={Ass} className={Style.assinatura}></img>
                            <img src={Ass2} className={Style.assinatura2}></img>
                        </div>

                        {/* <b>Nome:</b><strong>,</strong>
                <b>Categoria:</b><strong>{inscricao?.categoria}</strong>
                <b>Setor:</b>{inscricao?.setor?<strong>Público</strong>:<strong>Privado</strong>}
                Sua pré inscrição foi concluída com sucesso na data:
                <strong> {moment(inscricao?.dataHoraInclusao).format('DD/MM/YYYY')}</strong>
                <br />
                Seu número de protocolo é:
                <strong> {inscricao?.numeroProtocolo}</strong>
                <br /> */}
                    </div>
                    <div className={Style.footer}>
                        <img src={Logo} className={Style.logo}></img>

                    </div>


                </div>

                <div className={Style.button}>
                    <Spinner loading ={load}/>
                    <Button type="primary" htmlType="button" onClick={() => { donwloadComprovante() }} className={Style.ButtonDownload}>
                        <strong>Baixar Comprovante <VerticalAlignBottomOutlined className={Style.IconDownload} /></strong>
                    </Button>

                </div>

            </div>

        </>
    )
}

export default ProtocoloInscricaoPage;
