import Api from '../index'



const ConcursoService = {

    getConcursos: async (): Promise<any> => {
        return await Api.get(`/concurso`).then((res: any) => { return res.data.data });
    },

    getbyconcursoid: async (concursoID: any): Promise<any> => {
        return await Api.get(`/concurso/getbyconcursoid?concursoid=${concursoID}`)
            .then((res: any) => {
                return res.data
            });
    },
}


export default ConcursoService;