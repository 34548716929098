// import './Login.css'
import 'antd/dist/antd.css'
import Style from './login-page.module.scss'
import logoFinal from '../../../assets/LogoTexto.png'
import { Form, Input, Button, Select, message } from 'antd';
import { useEffect, useState } from 'react'
import UserService from '../../../services/User-Service/user-service'
import { any, bool, number } from 'prop-types';
import { useHistory, Link, useLocation } from 'react-router-dom';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import Token from '../../../services/Token-Service/token-service';
import Footer from '../../components/Footer/footer-component';
import { useAppSelector } from '../../../features/hooks';
import { getUsuarioLogado, usuarioLogadoState } from '../../../features/slices/usuario-logado-slice';
import { store } from '../../../features/store';
import Spinner from '../../../public/components/Spinner/spinner-component'


interface LoginProps {
    location: any;
}
const Login: React.FC<LoginProps> = (prop) => {
    let history = useHistory();
    const { Option } = Select;
    const [usuarioLogado, setUsuarioLogado] = useState<any>();
    const [disabled, setDisabled] = useState<boolean>();
    const [tokenAPI, setTokenAPI] = useState<any>();
    const [usuarioAutenticado, setUsuarioAutenticado] = useState<Boolean>();
    let [load, setLoad] = useState<boolean>(false);
    let usuario: any;
    const [form] = Form.useForm();
    let c = useAppSelector(usuarioLogadoState);
    const location = useLocation<any>();

    useEffect(() => {
        setDisabled(false);
        setUsuarioLogado(undefined);
        Token().then(response => {
            sessionStorage.setItem('Token', response);
            setTokenAPI(response);
        })
        if (location.state != undefined) {
            message.error(prop.location.state.message)
        }
    }, [])


    const onFinish = (values: any) => {
        
        if (usuarioLogado == undefined) {
            
            var data = {
                email: values.email,
                password: values.senha
            }
            setLoad(true)
            const headers = {
                "Authorization": "Bearer " + tokenAPI + ""
            }

            UserService.login(data)
                .then((response: any) => {
                    usuario = response.data.data;
                    setUsuarioLogado(usuario);
                    sessionStorage.setItem("usuarioLogado", JSON.stringify(usuario));
                    store.dispatch(getUsuarioLogado());
                    message.success("Usuário logado com sucesso!")
                    history.push(`/home`)
                })
                .finally(() => {
                    if (usuario !== undefined) {
                        setDisabled(true);
                        setUsuarioAutenticado(true);
                    }
                    setLoad(false);
                })
                .catch((error) => {
                    message.error(error.response?.data?.error ? error.response.data.error: "Erro inesperado!" )
                })
        }
        else {

        }
    };

    const onFinishFailed = (errorInfo: any) => {

    };

    return (
        <>
        <Spinner loading={load} />
            <div className={Style.container}>
                <div className={Style.formSignin}>
                    <img className={Style.logo} src={logoFinal} alt="" width="72" height="57" />
                    <h1 className={Style.titulo}>Login</h1>
                    <Form
                        form={form}
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                    >
                        <Form.Item
                            className={Style.inputContainer}
                            name="email"
                            rules={[
                                {
                                    required: true,
                                    message: 'Informe seu e-mail.',
                                },
                            ]}
                        >
                            <Input
                                placeholder="E-mail"
                                className={Style.input}
                                disabled={disabled}
                                id="email"

                            />
                        </Form.Item>

                        <Form.Item
                            name="senha"
                            className={Style.inputContainer}
                            rules={[
                                {
                                    required: true,
                                    message: 'Informe sua senha.',
                                },
                            ]}
                        >
                            <Input.Password
                                disabled={disabled}
                                className={Style.input}
                                id="senha"
                                placeholder="Senha"
                                iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                            />
                        </Form.Item>

                        <Form.Item className={Style.inputButton}>
                            <Button type="primary" htmlType="submit" id="logar" className={Style.confirmButton}>
                                Acessar
                            </Button>
                        </Form.Item>

                        <div className={Style.esqueciSenha}>
                            <Link to="esqueci-senha">
                                Esqueci minha senha
                            </Link>
                            <Link to="reenvio-email">
                                Reenviar e-mail de confirmação
                            </Link>
                        </div>
                    </Form>

                </div>
            </div>
        </>
    )
}
export default Login;