import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from '../store';
import UserService from "../../services/User-Service/user-service";
import { InscricaoModel } from "../Models/inscricao-model";
import InscricaoService from "../../services/Inscricao-Service/inscricao-service";
import moment from "moment";
import { RespostaWebAPI } from "../Models/respostawebapi-model";

interface Inscricao {
    inscricao: InscricaoModel,
    primeiraInscricao: any,
    loading: 'idle' | 'pending' | 'succeeded' | 'failed',
    responsePost: RespostaWebAPI<any> | null;
    responsePut: RespostaWebAPI<any> | null;
}

const initialState: Inscricao = {
    inscricao: {
        dataNascimento: "",
        cpfResponsavel: "",
        rg: 0,
        orgaoEmissor: "",
        cep: 0,
        uf: "",
        municipio: "",
        bairro: "",
        endereco: "",
        numero: 0,
        complemento: "",
        telefone1: "",
        telefone2: "",
        userID: "",
        concursoID: 0,
        email2: "",
        grauParentescoResponsavel: "",
        descricaoOutroGrauParentesco: "",
        nomeResponsavel:"",
        cnpjInstituicaoVinculacao: "",
        instituicaoVinculacao: "",
        descricaoOutraInstituicao: "",
        telefoneInstituicaoVinculacao: "",
        instituicaoVinculacaoPublica: "",
        nomeInstituicaoVinculacao: "",
        naoPossuiVinculo: true,
        cursoUniversidade: "",
        serieEscola: "",
        emailResponsavel: "",
        telefoneResponsavel:""

    },
    primeiraInscricao: {},
    loading: 'idle',
    responsePost: null,
    responsePut: null
};

interface param {
    concursoID: number,
    userID: string | undefined,
    inscricaoID: number
}


export const getByInscricaoID = createAsyncThunk('inscricao/getByInscricaoID', async (p: any) => {
    return await InscricaoService.getByInscricaoIDUserID(p.inscricaoID);
})

export const postInscricao = createAsyncThunk('inscricao/postInscricao', async (inscricao: InscricaoModel) => {

    return await InscricaoService.post(inscricao).catch((error)=> { return error.response});
})

export const putInscricao = createAsyncThunk('inscricao/putInscricao', async (inscricao: InscricaoModel) => {

    return InscricaoService.put(inscricao).catch((error)=> { return error.response;})
})

export const inscricaoSlice = createSlice({
    name: "inscricao",
    initialState,
    reducers: {
        increment: (state, action) => {
            state.primeiraInscricao = { ...state.primeiraInscricao, ...action.payload }
        },
        incrementPut: (state, action) => {
            state.inscricao = { ...state.inscricao, ...action.payload }
        },
        clearResponse: (state) => {
            state.responsePut = null
            state.responsePost = null
        },
        decrement: (state) => {
            state.primeiraInscricao = {}
            state.inscricao = initialState.inscricao
        }

    },
    extraReducers: (builder) => {

        builder.addCase(postInscricao.pending, (state, { payload }) => {            
            state.loading = 'pending'            
        })
        builder.addCase(putInscricao.pending, (state, { payload }) => {            
            state.loading = 'pending'
        })
        builder.addCase(postInscricao.fulfilled, (state, { payload }) => {  
            state.loading = 'succeeded';
            state.responsePost = payload?.data;                      
        })
        builder.addCase(putInscricao.fulfilled, (state, { payload }) => {
            state.loading = 'succeeded';
            state.responsePut = payload?.data;            
        })        
        builder.addCase(putInscricao.rejected, (state, { payload, error, type, meta }) => {                       
            state.loading = 'succeeded';                       
        })
        builder.addCase(postInscricao.rejected, (state, { payload, error, type, meta }) => {            
                        
            state.loading = 'succeeded';                        
        })
        builder.addCase(getByInscricaoID.pending, (state, action) => {
            state.loading = 'pending'
        })
        builder.addCase(getByInscricaoID.fulfilled, (state, { payload }) => {

            if (payload.success) {
                state.inscricao = payload.data;
                state.loading = 'succeeded';
            }
            else {
                state.loading = 'failed';
            }

        })

        builder.addCase(getByInscricaoID.rejected, (state, action) => {
            state.loading = 'failed';
        })

    }

});

export const { increment, incrementPut, clearResponse, decrement } = inscricaoSlice.actions;


export const inscricaoState = (state: RootState) => state.inscricao;

export default inscricaoSlice.reducer;