import { Button, Form, Input, Checkbox, message, Tooltip, notification } from 'antd';
import 'antd/dist/antd.css'
import FormItem from 'antd/lib/form/FormItem';
import React, { useState, useEffect } from 'react';
import { isValidCPF } from '../../../utils/cpf'
import Style from './cadastro-page.module.scss';
import { maskCpf } from '../../../utils/masks'
import MaskedInput from 'react-input-mask'
import UserService from '../../../services/User-Service/user-service'
import { useHistory } from 'react-router-dom';
import Spinner from '../../../public/components/Spinner/spinner-component'
import './style.module.less'
import { useAppSelector, useAppDispatch } from 'src/features/hooks';
import { useLocation } from 'react-router-dom';
import { FormInstance } from 'antd';
import { validarCaracteresEspeciaisENumeroNome } from 'src/utils/validators';
import { termosState } from 'src/features/slices/termos-slice';

interface Cadastro {
    location: any
}

const Cadastro: React.FC<Cadastro> = (props) => {
    let history = useHistory();
    let formRef = React.createRef<FormInstance>();
    const [form] = Form.useForm();
    const [ehEdicao, setEhEdicao] = useState<Boolean>(false);
    let [load, setLoad] = useState<boolean>(false);
    const location = useLocation<any>();
    let termos = useAppSelector(termosState);

    useEffect(() => {
        if (location.state?.user) {
            onFill(location);
            if (!location.state?.user.aceiteEdital || !location.state?.user.aceiteTermo) {
                message.error("É necessário fazer o aceite dos termos da tela de início para realizar o cadastro!");
                history.push('/home');
            }
        }
        else {
            if (termos.edital == false || termos.termo == false) {
                message.error("É necessário fazer o aceite dos termos da tela de início para realizar o cadastro!");
                history.push('/home');
            }
        }

    }, [termos]);

    const SubmitError = (errorInfo: any) => {

    };

    const unMasked = (input: string) => {
        return input
            .replace(/\D/g, "");
    };

    const voltarPagina = () => {
        history.push(`/home`)
    }

    const textoSenha = <span> A senha deve conter:<br />&#8226; Entre 6 - 20 caracteres.<br />&#8226; Pelo menos um caracter especial.<br />&#8226; Pelo menos um digito numérico.<br />&#8226; Pelo menos um caracter em caixa baixa ('a'-'z').<br />&#8226; Pelo menos um caracter em caixa alta ('A'-'Z').</span>

    const validarCPF = () => {
        var cpf = form.getFieldValue('cpf')
        if (!isValidCPF(cpf))
            message.error('CPF Inválido')
    }

    const onUpdate = () => {
        if (formRef.current!.getFieldValue('email') != formRef.current!.getFieldValue('confirmarEmail'))
            message.error('Os e-mails não conferem')

        else {
            let data = {
                id: location.state.user.id,
                cpf: formRef.current!.getFieldValue('cpf'),
                nome: formRef.current!.getFieldValue('nome'),
                email: formRef.current!.getFieldValue('email')
            }
            var cpf = '';

            if (data.cpf !== undefined) {
                cpf = unMasked(data.cpf);
                unMasked(cpf)
                data.cpf = cpf;
            }

            UserService.alterarCadastro(data).then((response) => {
                if (response.data.success) {
                    openNotification(data.email)
                    history.push(`/home`)
                }

            })
                .finally(() => {
                    setLoad(false)
                })
                .catch((error) => {
                    if (error.response != undefined) {
                        error.response.data.error.map((a: any) => {
                            message.error(a)
                        })
                    }
                    else {
                        message.error('Erro na conexão com servidor')
                    }
                })
        }

    }

    const onFill = (values: any) => {

        setEhEdicao(true);

        let user = values.state.user;

        //Informações do pessoa Slice      
        formRef.current!.setFieldsValue({
            cpf: user.cpf,
            nome: user.nome,
            email: user.email,

        })
    };

    const openNotification = (email: string) => {
        notification.success({
            message: <strong>Usuário cadastrado com sucesso</strong>,
            description: <p>Você receberá um e-mail de validação em <strong>{email}</strong> para acessar o sistema. Verifique a caixa de spam caso não encontre o e-mail na caixa de entrada. Caso não receba o e-mail, acesse nosso link de reenvio de e-mail na tela Login ou <a onClick={(e) => history.push('/reenvio-email')}>Clique Aqui</a>. </p>,
            duration: 0,
            style: { width: 700 },
            placement: 'top'
        });
    };

    const Submit = async (values: any) => {
        var cpf = '';

        if (values.cpf !== undefined) {
            cpf = unMasked(values.cpf);
            unMasked(cpf)
        }
        if (values.nome == undefined || values.cpf == undefined || values.senha == undefined || values.confirmarSenha == undefined || values.nome == "" || values.cpf == "" || values.senha == "" || values.confirmarSenha == "")
            message.error('Preencha todos os campos')

        else if (values.email != values.confirmarEmail)
            message.error('Os e-mails não conferem')

        else if (!isValidCPF(cpf))
            message.error('CPF inválido')

        else if (values.senha !== values.confirmarSenha)
            message.error('Senhas não conferem')

        else {
            setLoad(true)
            var data = {
                CPF: cpf,
                Nome: values.nome,
                Email: values.email,
                Password: values.senha,
                ConfirmPassword: values.confirmarSenha
            }
            UserService.registrar(data).then((response) => {
                var data2 = {
                    userID: response.data.data.id,
                    aceiteEdital: termos.edital,
                    aceiteTermo: termos.termo
                }
                UserService.aceitarEditalTermo(data2).then((response) => {
                    if (response.data.success) {
                        openNotification(values.email)
                        history.push(`/home`)
                    }
                }).finally().catch();



            })
                .finally(() => {
                    setLoad(false)
                })
                .catch((error) => {
                    if (error.response != undefined) {
                        error.response.data.error.map((a: any) => {
                            message.error(a)
                        })
                    }
                    else {
                        message.error('Erro na conexão com servidor')
                    }
                })
        }
    }

    return (
        <>
            <Spinner loading={load} />
            <div className={Style.Cadastro}>
                {ehEdicao ? <div className={Style.Titulo}>
                    Alterar Dados Cadastrais
                </div> : <div className={Style.Titulo}>
                    Cadastro
                </div>}

                <div className={Style.CadastroFormulario}>
                    <Form
                        autoComplete="off"
                        ref={formRef}
                        form={form}
                        name="FormCadastro"
                        onFinish={Submit}
                        onFinishFailed={SubmitError}
                        layout={'vertical'}
                    >
                        <FormItem name="nome" label="Nome" className={Style.FormName} rules={[{ required: true, message: "Campo Obrigatório" }, { type: 'string', min: 6, message: 'Nome precisa ter no mínimo 6 caracteres' },
                        {
                            validator: async () => {
                                if (form.getFieldValue("nome") && !validarCaracteresEspeciaisENumeroNome(form.getFieldValue("nome")?.toString())) {
                                    return Promise.reject(new Error('O campo nome deve conter apenas letras'))
                                }
                                return Promise.resolve()
                            }, validateTrigger: 'onChange'
                        }]}>

                            <Input placeholder="Informe o Nome Completo" className={Style.Inputs} />

                        </FormItem>

                        <FormItem name="cpf" label="CPF" className={Style.FormName} rules={[{ required: true, message: "Campo Obrigatório" }]}>

                            <MaskedInput className={Style.Inputs} alwaysShowMask={false} placeholder="Informe o CPF" onBlurCapture={() => { validarCPF() }} mask="999.999.999-99" />

                        </FormItem>

                        <FormItem name="email" label="E-mail" className={Style.FormName} rules={[{ required: true, message: "Campo Obrigatório" }]}>

                            <Input type="email" placeholder="Informe o E-mail" className={Style.Inputs} />

                        </FormItem>

                        <FormItem name="confirmarEmail" label="Confirmar E-mail" className={Style.FormName} rules={[{ required: true, message: "Campo Obrigatório" }]}>

                            <Input type="email" onPaste={(e) => e.preventDefault()} onPasteCapture={() => { message.warning('Digite o email novamente.'); }} placeholder="Informe o E-mail Novamente" className={Style.Inputs} />

                        </FormItem>

                        {!ehEdicao && <Tooltip trigger="click" overlay={textoSenha} overlayClassName={Style.TooltipCadastro} placement="bottomLeft" title={textoSenha}>
                            <FormItem name="senha" label="Senha" className={Style.FormName} rules={[{ required: true, message: "Campo Obrigatório" }]}>

                                <Input.Password className={Style.Inputs} placeholder="Informe a Senha" maxLength={20} />

                            </FormItem>
                        </Tooltip>}

                        {!ehEdicao && <FormItem name="confirmarSenha" label="Confirmar Senha" className={Style.FormName} rules={[{ required: true, message: "Campo Obrigatório" }]}>

                            <Input.Password className={Style.Inputs} placeholder="Informe a Senha Novamente" maxLength={20} />

                        </FormItem>}

                        {!ehEdicao && <div className={Style.reenvio}>
                            <a href="reenvio-email">
                                Reenviar email de confirmação
                            </a>
                        </div>}

                        <div className={Style.Buttons}>

                            <Button onClick={voltarPagina} className={Style.ButtonVoltar}>
                                Voltar
                            </Button>

                            <FormItem>
                                {ehEdicao ? <Button onClick={onUpdate} className={Style.ButtonCadastro}  >
                                    Alterar
                                </Button> : <Button htmlType='submit' disabled={!termos.edital && !termos.termo} className={Style.ButtonCadastro}  >
                                    Cadastrar
                                </Button>}
                            </FormItem>
                        </div>
                    </Form>
                </div>

            </div>
        </>
    )
}

export default Cadastro;