import React, { useEffect, useState } from 'react'
import { useLocation, Link, useHistory, useParams } from 'react-router-dom'
import Style from './alterar-senha.module.scss'
import Spinner from '../../components/Spinner/spinner-component';
import CodificarCaracteresEspeciaisQueryStrings from '../../../utils/codificar-caracteres-especiais-querystrings';
import UserService from '../../../services/User-Service/user-service';
import { Form, Input, Button, message, notification, Tooltip } from "antd";
import MaskedInput from 'react-input-mask'

const AlterarSenha: React.FC<any> = () => {
    let location = useLocation();
    const [html, setHtml] = useState(<></>)
    const [loading, setLoading] = useState(true)
    const [cpfCripto, setCpfCripto] = useState('')
    const [form] = Form.useForm();
    let history = useHistory();
    const { cpfCryptografado } = useParams<Record<string, string>>();

    useEffect(() => {
        setLoading(true);
        setCpfCripto(location.pathname.substring(15).replaceAll('+', CodificarCaracteresEspeciaisQueryStrings.execByCaracter('+')))
    }, [])

    const textoSenha = <span> A senha deve conter:<br />&#8226; Entre 6 - 20 caracteres.<br />&#8226; Pelo menos um caracter especial.<br />&#8226; Pelo menos um digito numérico.<br />&#8226; Pelo menos um caracter em caixa baixa ('a'-'z').<br />&#8226; Pelo menos um caracter em caixa alta ('A'-'Z').</span>

    const onFinish = (values: any) => {
        values.cpf = cpfCripto;
        UserService.alterarSenha(values).then((res) => {
            if (res.data.success)
                message.success("Senha alterada com sucesso!")
                history.push('/login')
        }).catch((error) => {
            if (error.response != undefined) {
                error.response.data.error.map((a: any) => {
                    message.error(a)
                })
            }
            else {
                message.error('Erro na conexão com servidor')
            }
        })
    }

    return (
        <>
            <div className={Style.container}>
                <h1 className={Style.titulo}>Alterar Senha</h1>
                <Form
                    layout="vertical"
                    form={form}
                    onFinish={onFinish}
                >
                    <Tooltip trigger="click" overlay={textoSenha} overlayClassName={Style.TooltipCadastro} placement="bottomLeft" title={textoSenha}>
                        <Form.Item name="senha" label="Senha" className={Style.FormName} rules={[{ required: true, message: "Campo Obrigatório" }]}>

                            <Input.Password className={Style.Inputs} placeholder="Informe a Senha" maxLength={20} />

                        </Form.Item>
                    </Tooltip>

                    <Form.Item name="confirmarSenha" label="Confirmar Senha" className={Style.FormName} rules={[{ required: true, message: "Campo Obrigatório" }]}>

                        <Input.Password className={Style.Inputs} placeholder="Informe a Senha Novamente" maxLength={20} />

                    </Form.Item>
                    <Form.Item className={Style.inputButton}>
                        <Button htmlType="submit" id="confirmar-dados" className={Style.confirmButton}>
                            Salvar
                        </Button>

                    </Form.Item>

                </Form>
            </div>
        </>
    )
}
export default AlterarSenha