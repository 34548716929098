import React, { useEffect, useState } from 'react';
import Styles from './header-component.module.scss';
import { MenuOutlined } from "@ant-design/icons";
import Logo from "../../../assets/Logo_governo negativo.svg";
import { useHistory, Link, useLocation } from 'react-router-dom';
import { store } from '../../../features/store';
import { useAppSelector } from '../../../features/hooks';
import { getUsuarioLogado, usuarioLogadoState } from '../../../features/slices/usuario-logado-slice';
import UserService from '../../../services/User-Service/user-service';

interface HeaderProps {
    usuarioLogado: any;
}

const Header: React.FC<any> = () => {
    useEffect(() => {
        store.dispatch(getUsuarioLogado());
    }, []);
    const location = useLocation();

    var usuario = useAppSelector(usuarioLogadoState);
    const history = useHistory();
    const [dropdown, setDropDown] = useState<Boolean>(false);
    const [isLoggedIn, setIsLoggedIn] = useState<Boolean>(!!sessionStorage.getItem('usuarioLogado'));
    var usuarioLogado = JSON.parse(sessionStorage.getItem('usuarioLogado') || "{}")
    var linkAdministrativo = process.env.REACT_APP_BASEURL_ADMIN;

    const moduloVotacaoPopular = () => {
        return location.pathname.includes('/confirmar-voto') || location.pathname.includes('/votacao-popular')
    }

    const logout = () => {
        sessionStorage.removeItem('usuarioLogado');
        window.location.reload();
    }

    return (
        <>
            <div className={Styles.header}>
                <a onClick={(e) => history.push('/concurso')}>
                    <img src={Logo} className={Styles.logo} />
                </a>

                <div className={Styles.links_full}>
                    {!moduloVotacaoPopular() ?
                        UserService.estaLogado() ?
                            <ul>
                                <li><a onClick={(e) => history.push('/concurso')}>Início</a></li>
                                <li><a>{usuario == undefined ? null : usuario.nome}</a></li>
                                <li><a href={linkAdministrativo}>Acesso Administrativo</a></li>
                                <li><a onClick={(e) => history.push('/votacao-popular')}>Votação Popular</a></li>
                                <li><a onClick={(e) => logout()}>Sair</a></li>
                            </ul>
                            : <ul>
                                <li><a onClick={(e) => history.push('/concurso')}>Início</a></li>
                                <li><a onClick={(e) => history.push('/login')}>Acesso Participante</a></li>
                                <li><a href={linkAdministrativo}>Acesso Administrativo</a></li>
                                <li><a onClick={(e) => history.push('/votacao-popular')}>Votação Popular</a></li>
                            </ul>
                        : null
                    }


                </div>
                <div className={Styles.links_collapse}>
                    {moduloVotacaoPopular() ? null : <button onClick={() => setDropDown(!dropdown)}><MenuOutlined /></button>}
                </div>

            </div>
            {!moduloVotacaoPopular() ?
                dropdown && UserService.estaLogado() ? <div className={Styles.links_dropdown}>
                    <div><a onClick={(e) => { history.push('/concurso'); setDropDown(!dropdown) }}>Início</a></div>
                    <div><a>{usuario == undefined ? null : usuario.nome}</a></div>
                    <div><a onClick={(e) => { history.push('/votacao-popular'); }}>Votação Popular</a></div>
                    <div><a onClick={(e) => { logout(); setDropDown(!dropdown) }}>Sair</a></div>
                </div> : dropdown && !UserService.estaLogado() ?
                    <div className={Styles.links_dropdown}>
                        <div><a onClick={(e) => { history.push('/concurso'); setDropDown(!dropdown) }}>Início</a></div>
                        <div><a onClick={(e) => { history.push('/login'); setDropDown(!dropdown) }}>Acesso Participante</a></div>
                        <div><a href={linkAdministrativo}>Acesso Administrativo</a></div>
                        <div><a onClick={(e) => { history.push('/votacao-popular'); }}>Votação Popular</a></div>
                    </div> : null
                : null}
        </>
    )
}

export default Header;
