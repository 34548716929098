import React, { useEffect, useState } from 'react'
import { useLocation, Link } from 'react-router-dom'
import Style from './email-confirmado-component.module.scss'
import Spinner from '../Spinner/spinner-component';
import CodificarCaracteresEspeciaisQueryStrings from '../../../utils/codificar-caracteres-especiais-querystrings';
import UserService from '../../../services/User-Service/user-service';
import MaskedInput from 'react-input-mask'
import { Button, Form } from 'antd';
import FormItem from 'antd/lib/form/FormItem';
import { unMasked } from '../../../utils/cpf';
import { isPropertySignature } from 'typescript';

interface EmailConfirmadoComponentProps { 
    loading: boolean,
    messageRequest: string,
    methodOnFinish: any
    labelCpf: string
    ocultar : boolean

}

const EmailConfirmadoComponent: React.FC<EmailConfirmadoComponentProps> = (props) => { 

    return (
        <>
            <div className={Style.emailConfirmadoDiv}>
                {props.messageRequest}
                <Form onFinish={props.methodOnFinish}>
                    {props.ocultar === false ? <h3>{props.labelCpf}</h3> : null}
                    <Form.Item
                        hidden={props.ocultar}
                        rules={[{ required: true, message: 'Campo CPF é obrigatório' }]}
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                        name='cpf'
                        className={Style.FormName}>
                        <MaskedInput
                            className={Style.input}
                            alwaysShowMask={false}
                            placeholder="Informe o CPF:"
                            mask="999.999.999-99" />
                    </Form.Item >
                    <div className={Style.container}>
                        <Button
                            hidden={props.ocultar}
                            type='primary'
                            className={Style.btn}
                            htmlType='submit' >Confirmar</Button>
                    </div>
                </Form>
            </div>
            <Spinner loading={props.loading} />
        </>
    )
}
export default EmailConfirmadoComponent