import React, { useState, useEffect } from 'react'
import { Form, Input, Button, Select, InputNumber, message, Spin, Modal, Checkbox } from 'antd';
import { FormInstance } from 'antd/es/form';
import MaskedInput from 'react-input-mask';
import moment from 'moment';
import { useAppSelector, useAppDispatch } from '../../../features/hooks';
import { isValidCPF } from '../../../utils/cpf';
import { DesenhoModel } from '../../../features/Models/desenho-model';
import Styles from '../root.module.scss';
import { usuarioLogadoState } from '../../../features/slices/usuario-logado-slice';
import { InscricaoModel } from '../../../features/Models/inscricao-model';
import { increment, incrementPut, inscricaoState } from '../../../features/slices/inscricao-slice';
import axios from 'axios';
import { Document, Page } from 'react-pdf';
import './pdf.css';
import pdf from "../../../assets/edital/editalBase64pdf";
import './style.less'
import { validarCEPonSubmit, validarCheckbox } from 'src/utils/validators';

interface FormEndereco {
    ehEdicao: boolean,
    parentHandle: any,

    // dadosInscricao:InscricaoModel[] | undefined
}

const FormEndereco: React.FC<FormEndereco> = (props: FormEndereco) => {
    let formRef = React.createRef<FormInstance>();
    const [loading, setLoading] = useState(false);
    const [erros, setErros] = useState<any>();

    const [errorStyle] = useState<Array<Object>>([{}]);
    const [form] = Form.useForm();

    let responseSlice = useAppSelector(inscricaoState);
    let inscricao = responseSlice.primeiraInscricao;
    const [mask1, setMask1] = useState("(99) 99999-9999");
    const [mask2, setMask2] = useState("(99) 99999-9999");

    const [isModalVisible, setIsModalVisible] = useState(false);
    const [base64Edital, setBase64Edital] = useState("");
    const [numPages, setNumPages] = useState(null);
    const [disable, setDisable] = useState(false);
    const [visibleCheck, setVisibleCheck] = useState(false);
    const [check, setCheck] = useState(false);

    const [ehEdicao, setEhEdicao] = useState(false);
    let post = responseSlice.responsePost;

    if (props.ehEdicao) {
        inscricao = responseSlice.inscricao
    }

    const dispatch = useAppDispatch();

    useEffect(() => {
        onFill();

    }, []);

    useEffect(() => {
        if (post?.success) {
            setEhEdicao(true)
        }
    }, [post]);

    const onFinish = (values: any) => {
        dispatch(increment(values));
        if (props.ehEdicao) {
            dispatch(incrementPut(values));
        }
        props.parentHandle("3");
    };

    const onFinishFailed = (data: any) => {
        let res = [];
        res = data.errorFields.map((p: any) => p.name[0]);
        setErros(res);
    }

    const voltarTab = () => {
        props.parentHandle("1");
    }

    const onReset = () => {
        formRef.current!.resetFields();
    };

    const onFill = () => {
        // if(props.dadosInscricao != undefined){

        // Informações do Inscrição Slice
        formRef.current!.setFieldsValue({
            telefone1: inscricao?.telefone1,
            telefone2: inscricao?.telefone2,
            cep: inscricao?.cep,
            uf: inscricao?.uf,
            municipio: inscricao?.municipio,
            bairro: inscricao?.bairro,
            endereco: inscricao?.endereco,
            numero: inscricao?.numero,
            complemento: inscricao?.complemento,
            aceiteEdital: inscricao?.aceiteEdital
        })
        setCheck(inscricao?.aceiteEdital)
        // }
    };
    function callback(key: any) {

    }

    const buscarEndereco = () => {
        try {
            var cep = formRef.current?.getFieldValue("cep")
            cep = cep.replace(/\D/g, '');
            if (cep != "") {
                //Expressão regular para validar o CEP.
                var validacep = /^[0-9]{8}$/;
                if (validacep.test(cep)) {
                    setLoading(true);
                    axios.get(`https://viacep.com.br/ws/${cep}/json/`)
                        .then((response) => {
                            if (response.data?.erro) {
                                message.error('CEP inválido')
                                form.setFieldsValue({
                                    uf: "",
                                    municipio: "",
                                    bairro: "",
                                    endereco: "",
                                })
                            }
                            else if (response.data?.uf != "MS") {
                                form.setFieldsValue({
                                    uf: "",
                                    municipio: "",
                                    bairro: "",
                                    endereco: "",
                                })
                                message.error('Inscrição válida apenas para residentes do estado de MS')
                            }
                            else {
                                form.setFieldsValue({
                                    uf: response.data?.uf,
                                    municipio: response.data?.localidade,
                                    bairro: response.data?.bairro,
                                    endereco: response.data?.logradouro,
                                })
                            }

                        })
                        .catch((ex) => {
                            message.error('Falha na requisição do CEP')
                        })
                        .finally(() => {
                            setLoading(false);
                        })
                }
                else
                    message.error('CEP inválido')
            }
        }
        catch {
            message.error('CEP inválido')
        }
    }

    const onChangeCepMask = () => {

        var cep = formRef.current?.getFieldValue('cep')
        cep = cep.replace(/D/g, "")
        cep = cep.replace(/^(\d{5})(\d)/, "$1-$2")
        formRef.current?.setFieldsValue({ cep: cep })
    }


    const showModal = () => {
        setCheck(false)
        setIsModalVisible(true)
    };

    const onDocumentLoadSuccess = (n: any) => {
        setNumPages(n._pdfInfo.numPages)
    }

    const aceitar = () => {
        setIsModalVisible(false)
    };

    const handleCancel = () => {
        if (!check) {
            message.error("Leia o edital para realizar o aceite.")
        }
        else {
            setIsModalVisible(false);
        }
    };

    const onScrolll = (e: any) => {
        var target = e.target
        if (target.scrollHeight - target.scrollTop === target.clientHeight) {
            setVisibleCheck(true)
            setDisable(false)
        }
        else {
            setVisibleCheck(false)
            setDisable(true)
        }
    }

    const aceiteEdital = (e: any) => {
        setDisable(!e.target.checked)
        setCheck(e.target.checked)
    }

    return (
        <Spin spinning={loading}>
            <Form
                autoComplete="off"
                className={Styles.formulario}
                ref={formRef}
                form={form}
                name="control-ref"
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                style={{ padding: '0', marginBottom: '2%' }}
            >
                <fieldset disabled={props.ehEdicao || ehEdicao}>

                    <div className={Styles.groupItem}>

                        <Form.Item
                            style={{ marginTop: '-10px', width: '50%' }}
                            name="telefone1"
                            labelCol={{ span: 24 }}
                            wrapperCol={{ span: 24 }}
                            label="Telefone 1"
                            rules={[{ required: true, message: "O campo Telefone 1 é obrigatório" }]}
                            shouldUpdate={true}
                        >
                            <MaskedInput className={erros?.find((e: any) => e == 'telefone1') ? Styles.itemError : Styles.item} alwaysShowMask={false} mask={mask1}
                                onBlur={e => {
                                    if (e.target.value.replace("_", "").length === 14) {
                                        setMask1("(99) 9999-9999");
                                    }
                                }}
                                onFocus={e => {
                                    if (e.target.value.replace("_", "").length === 14) {
                                        setMask1("(99) 99999-9999");
                                    }
                                }} />
                        </Form.Item>

                        <Form.Item

                            style={{ marginTop: '-10px', width: '50%' }}
                            name="telefone2"
                            labelCol={{ span: 24 }}
                            wrapperCol={{ span: 24 }}
                            label="Telefone 2"
                        >

                            <MaskedInput className={Styles.item} alwaysShowMask={false} mask={mask2}
                                onBlur={e => {
                                    if (e.target.value.replace("_", "").length === 14) {
                                        setMask2("(99) 9999-9999");
                                    }
                                }}
                                onFocus={e => {
                                    if (e.target.value.replace("_", "").length === 14) {
                                        setMask2("(99) 99999-9999");
                                    }
                                }} />
                        </Form.Item>

                    </div>

                    <div className={Styles.groupItem}>

                        <Form.Item
                            style={{ marginTop: '-10px', width: '60%' }}
                            name="cep"
                            label="CEP"
                            labelCol={{ span: 24 }}
                            wrapperCol={{ span: 24 }}
                            rules={[
                                { required: true, message: "O campo CEP é obrigatório" },
                                { validator: validarCEPonSubmit, validateTrigger: "onSubmit" },
                                // { validator: buscarEndereco, validateTrigger:"onBlur"  }
                            ]}
                        >
                            {/* {(props)=>{                          
                            return(
                                <Form.Item name="other">
                                <Input />
                              </Form.Item>
                            );
                        }} */}
                            <MaskedInput mask="99999-999" className={erros?.find((e: any) => e == 'cep') ? Styles.itemError : Styles.item}
                                onChange={e =>
                                    onChangeCepMask()}
                                onBlurCapture={() => { buscarEndereco() }}
                            />
                        </Form.Item>
                        <Form.Item
                            style={{ marginTop: '-10px', width: '40%' }}
                            name="numero"
                            labelCol={{ span: 24 }}
                            wrapperCol={{ span: 24 }}
                            label="Número"
                            rules={[{ required: true, message: "O campo Número é obrigatório" }]}
                            initialValue="0"
                        >
                            <Input type="number" className={Styles.item} />
                        </Form.Item>

                    </div>

                    <Form.Item
                        style={{ marginTop: '-10px' }}
                        name="bairro"
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                        label="Bairro"
                        rules={[{ required: true, message: "O campo Bairro é obrigatório" }]}
                    >
                        <Input className={Styles.item} />
                    </Form.Item>

                    <Form.Item
                        style={{ marginTop: '-10px' }}
                        name="endereco"
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                        label="Endereço"
                        rules={[{ required: true, message: "O campo Endereço é obrigatório" }]}
                    >
                        <Input className={Styles.item} />
                    </Form.Item>

                    <Form.Item
                        style={{ marginTop: '-10px' }}
                        name="complemento"
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                        label="Complemento"
                        rules={[{ required: false }]}
                    >
                        <Input className={Styles.item} />
                    </Form.Item>

                    <div className={Styles.groupItem}>

                        <Form.Item
                            style={{ marginTop: '-10px', width: '60%' }}
                            name="municipio"
                            labelCol={{ span: 24 }}
                            wrapperCol={{ span: 24 }}
                            label="Município"
                            rules={[{ required: true, message: "O campo Município é obrigatório" }]}
                        >
                            <Input className={Styles.item} disabled />
                        </Form.Item>

                        <Form.Item
                            style={{ marginTop: '-10px', width: '40%' }}
                            name="uf"
                            label="UF"
                            labelCol={{ span: 24 }}
                            wrapperCol={{ span: 24 }}
                            rules={[{ required: true, message: "O campo UF é obrigatório" }]}
                        >
                            <Input className={Styles.item} disabled />
                        </Form.Item>

                    </div>

                    {/* <Form.Item
                    style={{ marginTop: '-10px' }}
                    name="aceiteEdital"
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    valuePropName="checked"
                    rules={[{ validator: validarCheckbox, message: "O campo aceite é obrigatório", validateTrigger: "onChange" }]}
                >
                    <Checkbox disabled={check} checked={check} onChange={(e) => { showModal() }}> Termo do edital para aceite</Checkbox>
                </Form.Item> */}
                </fieldset>
                <div className={Styles.buttonsArea}>
                    <Button type="primary" className={Styles.proximo} onClick={voltarTab}>
                        Voltar
                    </Button>
                    <Button type="primary" className={Styles.proximo} htmlType="submit">
                        Próximo
                    </Button>
                </div>

            </Form>

            <Modal className={Styles.modal} title="Edital" visible={isModalVisible} onCancel={handleCancel}
                footer={[
                    <div className={Styles.buttonsAceite}>
                        <Checkbox disabled={!visibleCheck} onChange={(e) => { aceiteEdital(e) }}> Declaro ter ciência e estar de acordo com o termo acima</Checkbox>,
                        <Button
                            type="primary"
                            onClick={aceitar}
                            disabled={disable}
                        >
                            Aceitar
                        </Button>
                    </div>
                ]}>
                <div className={Styles.pdfContainer} id="pdfContainer" onScroll={(e) => { onScrolll(e) }}>

                    <Document
                        className={Styles.pdf}
                        file={base64Edital}
                        options={{ workerSrc: "./pdf.worker.js" }}
                        onLoadSuccess={onDocumentLoadSuccess}
                    >
                        {Array.from(new Array(numPages), (el, index) => (
                            <Page key={`page_${index + 1}`} pageNumber={index + 1} />
                        ))}
                    </Document>

                </div>

            </Modal>
        </Spin>

    )
}

export default FormEndereco;
