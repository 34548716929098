import React, { useEffect, useState } from 'react';
import Styles from './inscricaobutton-component.module.scss';
import { useHistory } from 'react-router-dom';
import Spinner from '../../components/Spinner/spinner-component';
import Modal from '@material-ui/core/Modal';
import DesenhoService from 'src/services/Desenho-Service/desenho-service';
import InscricaoService from 'src/services/Inscricao-Service/inscricao-service';
import ConcursoService from 'src/services/Concurso-Service/concurso-service';

interface InscricaoButtonProps {
  inscricao: any,
  habilitado: boolean,
  visible: boolean,
  foraPeriodoInscricao: boolean
}

const InscricaoButton: React.FC<InscricaoButtonProps> = (props) => {

  const [open, setOpen] = React.useState(false);
  let [load, setLoad] = useState<boolean>(false);
  let [aceitouTermos, setAceitouTermos] = useState<boolean>(false);
  const [confirmLoading, setConfirmLoading] = React.useState(false);
  const [modalText, setModalText] = React.useState('Content of the modal');
  const [visible, setVisible] = useState<boolean>(false);
  const [podeAcessarTelaInscricao, setPodeAcessarTelaInscricao] = useState(false);
  const [foraPeriodoInscricao, setForaPeriodoInscricao] = useState(false);
  let history = useHistory();

  useEffect(() => {
    setForaPeriodoInscricao(props.foraPeriodoInscricao)
  }, [props.foraPeriodoInscricao])

  useEffect(() => {
    setAceitouTermos(props.habilitado)

  }, [props.habilitado])

  useEffect(() => {
    setVisible(props.visible)
  }, [props.visible])

  useEffect(() => {
    if (props?.inscricao?.inscricaoID != undefined && temInscricao())
      podeAcessarInscricao(props?.inscricao?.inscricaoID).then((res) => { setPodeAcessarTelaInscricao(res) })

  }, [props?.inscricao?.inscricaoID])

  const showModal = () => {
    setLoad(true);
    // setTimeout(() => {
    let user: any = sessionStorage.getItem('usuarioLogado');

    if (user) {
      history.push('/inscricao');
    } else {
      setOpen(true);
      setLoad(false);
    }
    // }, 2000);

  };

  const podeAcessarInscricao = async (inscricaoID: number) => {
    setLoad(true)
    return InscricaoService.inscricaoFinazalida(inscricaoID).then((resposta: boolean) => {
      return !resposta && aceitouTermos
    })
      .finally(() => setLoad(false))
      .catch(() => false);
  }

  const validaQueroMeInscrever = () => {
    let user: any = sessionStorage.getItem('usuarioLogado');
    return !temInscricao() && !aceitouTermos && user
  }

  const temInscricao = () => {
    return props.inscricao != undefined && props.inscricao != null
  }

  const handleCancel = () => {
    setOpen(false);
  };

  const body = (
    <>
      <div className={Styles.box}>

        <div className={Styles.actionsArea}>
          <button className={Styles.buttonClose} onClick={handleCancel}>x</button>
          <button className={Styles.actionButton} onClick={() => history.push('/cadastro')}>
            Cadastre-se primeiro
          </button>
          <button className={Styles.actionButton} onClick={() => history.push('/login')}>
            Login após o cadastro
          </button>
        </div>
      </div>
    </>
  );

  return (
    <>
      {temInscricao() ?
          <div className={Styles.buttonArea}>
            <button className={Styles.inscricaoButton} onClick={() => {
              if (podeAcessarTelaInscricao) {
                history.push('/inscricao/' + props.inscricao.inscricaoID)
              }
              else {
                history.push('/protocolo-inscricao/' + props.inscricao.inscricaoID)
              }
            }
            }>
              {podeAcessarTelaInscricao ? "Ver minha inscrição" : "Baixar comprovante de inscrição"}
              <Spinner loading={load} />
            </button>
          </div>

          :
          foraPeriodoInscricao ? <strong className={Styles.mensagemForaPeriodo}>Fora do período de inscrição</strong> :
          <>
            <div className={Styles.buttonArea}>
              {/* <button disabled={validaQueroMeInscrever()} className={Styles.inscricaoButton} onClick={showModal}/> */}
              <button disabled={!props.habilitado} className={visible ? Styles.queroInscreverButton : Styles.inscricaoButton} onClick={showModal}>
                Quero me Inscrever
                <Spinner loading={load} />
              </button>

              {props.habilitado ? null : <strong className={Styles.mensagemAceite}>É necessário ler e aceitar os termos acima para realizar o seu cadastro e inscrição</strong>}
            </div>

            <Modal
              open={open}
              onClose={handleCancel}
              aria-labelledby="simple-modal-title"
              aria-describedby="simple-modal-description"
            >
              {body}
            </Modal>
          </>
      }
    </>
  )
}


export default InscricaoButton;
