import { VotoPopularModel } from 'src/features/Models/votoPopular-model';
import Api from '../index'

const VotoPopularService = {

    getTodosVotos: async (): Promise<any> => {
        return await Api.get(`/VotoPopular/getTodosVotos`).then((res: any) => {
            return res.data
        });
    },

    put: async (votoPopular: any): Promise<any> => {
        const body = JSON.stringify(votoPopular);
        return await Api.put(`/VotoPopular`, body).then((res) => { return res })
    },
    
    getDesenhoClassificadoPorCategoriaModalidade: async (concursoID: string | undefined): Promise<any> => {
        return await Api.get(`/desenho/getdesenhoclassificadoporcategoriamodalidade?concursoid=${concursoID}`).then((res: any) => {
            return res.data
        });
    },

    postVotoPopular: async (votoPopular: VotoPopularModel): Promise<any> => {
        const body = JSON.stringify(votoPopular);
        return await Api.post(`/desenho/votarpopular`, body).then((res: any) => {
            return res.data
        });
    },

    postConfirmarVoto: async (votoPopular: VotoPopularModel): Promise<any> => {
        const body = JSON.stringify(votoPopular);
        return await Api.post(`/desenho/confirmarvoto`, body).then((res: any) => {
            return res.data
        });
    }
}

export default VotoPopularService