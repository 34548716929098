import React, { useEffect } from 'react';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import Filtro from './public/components/Filtro/filtro-component';
import Pesquisa from './public/components/Pesquisa/pesquisa-component';
import EmailConfirmado from './public/pages/Email-Confirmado/email-confirmado';
import Home from './public/pages/Home/home-page';
import cardInscricoes from './secure/components/CardInscricoes/cardInscricoes';
import Login from './public/pages/Login/login-page';
import Concurso from './public/pages/Concurso/concurso-page';
import Cadastro from './public/pages/Cadastro/cadastro-page';
import Inscricao from './secure/pages/Inscricao/inscricao-page';
import Error from './secure/pages/Error/error-page';
import Header from './public/components/Header/header-component';
import Footer from './public/components/Footer/footer-component';
import globalStyle from './styles/globalStyle.module.scss';
import ProtocoloInscricaoPage from './secure/pages/Protocolo-Inscricao/protocolo-inscricao-page';
import Reenvio from './public/pages/Reenvio-Email/reenvio-email';
import EsqueciSenha from './public/pages/Esqueci-Senha/esqueci-senha';
import AlterarSenha from './public/pages/Alterar-Senha/alterar-senha';
import VotoPopular from './public/pages/Votacao-Popular/votacao-popular';
import NotFoundPage from './secure/pages/Not-Found/not-found-page';
import EmailConfirmadoResponsavel from './public/pages/Email-Confirmado-Responsavel/email-confirmado-responsavel';
import ConfirmarVoto from './public/pages/Confirmar-Voto/confirmar-voto';
import RotinaTempEmail from './public/pages/Rotina-Temp-Email/rotina-temp-email';

const Routes = (props) => {

    const isLoggedIn = () => !!sessionStorage.getItem('usuarioLogado');

    const SecuredRoute = ({ component: Component, ...rest }) => (

        <Route
            {...rest}
            render={props =>
                isLoggedIn() === true ? (
                    <Component {...props} />
                ) : (
                    <Redirect
                        to={{
                            pathname: "/login",
                            state: { message: "É necessario realizar o Login!" }
                        }}
                    />
                )
            }
        />
    );

    return (

        <BrowserRouter>
            <Header />
            <div className={globalStyle.headerSombra}></div>
            <div className={globalStyle.pageContainer}>
                
                <Switch >
                    <Route exact path="/" component={Concurso} />
                    <Route path="/home" component={Concurso} />
                    <Route path="/cardinsc" component={cardInscricoes} />
                    <Route path="/concurso" component={Concurso} />
                    <Route path="/login" component={Login} />
                    <Route path="/error" component={Error} />
                    <Route path="/reenvio-email" component={Reenvio} />
                    <Route path="/esqueci-senha" component={EsqueciSenha} />
                    <Route path="/alterar-senha/:cpfCryptografado" component={AlterarSenha} />
                    <Route path="/email-confirmado/:cpfCryptografado" component={EmailConfirmado} />
                    <Route path="/cadastro" component={Cadastro} />
                    <Route path="/email-confirmado-responsavel/:id" component={EmailConfirmadoResponsavel} />
                    <Route path="/redirect-educacaofiscal" component={() => { window.location = 'https://www.educacaofiscal.ms.gov.br/7477-2/'; return null;} }/>
                    {/* <Route path="/inscricao" component={Inscricao}></Route> */}
                    {/* <Route path="/login" component={Login}></Route>
                    <Route path="/error" component={Error}></Route> */}
                    <SecuredRoute  exact path="/inscricao" component={Inscricao} />
                    <SecuredRoute path="/protocolo-inscricao/:id/" component={ProtocoloInscricaoPage} />
                    <SecuredRoute path="/inscricao/:id" component={Inscricao} />
                    <Route path="/votacao-popular" component={VotoPopular} />
                    {/*<Route path="/confirmar-voto/:infoVotoCripto" component={ConfirmarVoto} /> */}
                    <Route path="/rotina-temp-email" component={RotinaTempEmail} />
                    <Route path="*" component={NotFoundPage} />
                    
                </Switch>

            </div>
            <Footer />
        </BrowserRouter>

    );
};

export default Routes;