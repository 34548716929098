import axios from "axios";

export const validarCheckbox = (a: any, value: any) => {
    if (!value) {
        return Promise.reject(new Error('Preencha o checkbox!'));
    }
    return Promise.resolve();
};

export const validarCEPonSubmit = async (a: any, value: any, callback: any) => {

    var cep = value;
    if (cep != undefined) {
        cep = cep.replace(/\D/g, '');
        if (cep != "") {
            //Expressão regular para validar o CEP.
            var validacep = /^[0-9]{8}$/;

            if (validacep.test(cep)) {
                await axios.get(`https://viacep.com.br/ws/${cep}/json/`)
                    .then((response) => {
                        if (response.data?.erro) {
                            return Promise.reject(new Error('CEP inválido'));
                        }
                        else if (response.data?.uf != "MS") {
                            return Promise.reject(new Error('Inscrição válida apenas para residentes do estado de MS'));

                        } else {
                            return Promise.resolve();
                        }
                    })
                    .catch((ex) => {
                        return Promise.reject(ex);
                    })
                    .finally(() => {
                    })
            }
            else
                return Promise.reject(new Error('CEP inválido'));

        }
    }
    else {
        return Promise.reject(new Error('CEP inválido'));
    }
}

export const ehMenorQueIdadeAceita = (dataNascimento: Date, idadeAceita: number) => {

    var hoje = new Date();

    //Retorna a diferença entre hoje e a data de nascimento em anos.
    var ano = hoje.getFullYear() - dataNascimento.getFullYear();
    //Retorna a diferença de mês do mês de nascimento para o atual.
    var m = hoje.getMonth() - dataNascimento.getMonth();
    //Caso ainda não tenha ultrapassado o dia e o mês
    if (m < 0 || (m === 0 && hoje.getDate() < dataNascimento.getDate())) {
        ano--;
    }

    if (ano < idadeAceita) {
        return true;
    } else {
        return false;
    }


}

export const validarCaracteresEspeciaisENumeroNome = (string: string) => {
    var char = string ? string : "";
    var pattern = /^[A-Za-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ'\s]+$/;
    if (char.match(pattern)) {
        return true;
        //return Promise.resolve();
    }
    else {
        return false;
        //return Promise.reject(new Error('O campo nome deve conter apenas letras'));
    }
}
