import React from 'react';
import { useHistory, Redirect, useLocation } from 'react-router-dom';
import Styles from './rotina-temp-email.module.scss';
import { Button, Col, message, Progress, Row } from 'antd';
import { useEffect } from 'react';
import { useState } from 'react';
import Spinner from 'src/public/components/Spinner/spinner-component';
import { dominiosComuns } from '../Votacao-Popular/dominioEmailComum'
import axios from 'axios';
import VotoPopularService from '../../../services/VotoPopular-Service/votoPopular-service';

const RotinaTempEmail: React.FC = (props) => {
    const history = useHistory();
    const location = useLocation();
    const [loading, setLoading] = useState<boolean>(false);
    const [votoPopulars, setVotoPopulars] = useState<any[]>([]);
    const [percent, setPercent] = useState<number>(0);

    useEffect(() => {
        VotoPopularService.getTodosVotos().then((res) => {
            setVotoPopulars(res);
        })
    }, []);

    const ValidarEmails = () => {
        setLoading(true);
        votoPopulars.map((v: any, index) => {
            setTimeout(() => {
                let emailSeparado = v.email.split("@");
                //validação dos pontos no e-mail
                if (emailSeparado[1].toUpperCase().includes("GMAIL")) {
                    let emailFormatado = FormataGmail(v.email);
                    let votoEmailOriginal = votoPopulars.find(vp => vp.email == emailFormatado && vp.desenho.modalidadeDesenhoID == v.desenho.modalidadeDesenhoID && vp.desenho.inscricao.categoriaID == v.desenho.inscricao.categoriaID)
                    if (votoEmailOriginal != undefined && votoEmailOriginal?.votoPopularID != v.votoPopularID) {
                        v.Excluido = true;
                        VotoPopularService.put(v).then(() => { }).catch((err) => {
                            console.log(err);
                        })
                        console.log("Voto invalido", v.email);
                    }
                }
                //valida temp e-mail
                ValidaTempEmail(v.email).then((res) => {
                    if (!res) {
                        console.log("Voto invalido", v.email);
                        v.Excluido = true;
                        VotoPopularService.put(v).then(() => { }).catch((err) => {
                            console.log(err);
                        })
                    }
                }).finally(() => {

                })
                setPercent(((index + 1) / votoPopulars.length) * 100)
            }, 1500);
        })
        setLoading(false);
    }

    const FormataGmail = (email: string) => {
        let emailSeparado = email.split("@");
        if (emailSeparado[1].toUpperCase().includes("GMAIL")) {
            let email = emailSeparado[0].replaceAll(".", "");
            return `${email}@gmail.com`
        } else {
            return email;
        }
    }

    const ValidaTempEmail = async (email: string): Promise<any> => {
        return new Promise<any>(async (resolve, reject) => {
            var dominio = email.split('@')[1]

            if (dominiosComuns.includes(dominio)) {
                resolve(true);
            }
            else {
                axios.get(`https://mailcheck.p.rapidapi.com/?domain=${dominio}`, {
                    headers: {
                        'Content-Type': 'application/json',
                        "Access-Control-Allow-Origin": "*",
                        "Access-Control-Allow-Headers": "Authorization",
                        "Access-Control-Allow-Methods": "GET, POST, OPTIONS, PUT, PATCH, DELETE",
                        "X-RapidAPI-Key": "0ee588be9cmsh26b9ea2c255cda6p17aaf5jsn7f70a45cdf23",
                        "X-RapidAPI-Host": "mailcheck.p.rapidapi.com",
                    }
                })
                    .then((response) => {
                        if (response.data.valid && !response.data.block && !response.data.disposable) {
                            resolve(true);
                        }
                        else {
                            resolve(false);
                        }
                    })
                    .catch((ex: any) => {
                        message.error('Falha na consulta de E-mail')
                        resolve(true);
                    })
                    .finally(() => {
                    })
            }
        });
    }

    const ResetExcluidos = () => {
        setLoading(true);
        votoPopulars.map((vp, index) => {
            if (vp.Excluido) {
                vp.Excluido = false;
                VotoPopularService.put(vp).then(() => { }).catch((err) => { })
            }
            setPercent(((index + 1) / votoPopulars.length) * 100)
        })
        setLoading(false);
    }

    return (
        <>
            <Spinner loading={loading}></Spinner>
            <div className={Styles.alignCenter}>
                <Progress type="circle" percent={percent} style={{ marginLeft: 8 }} />
                <Row className={Styles.buttons}>
                    <Col span={12}>
                        <Button onClick={ValidarEmails}>Start</Button>
                    </Col>
                    <Col span={12}>
                        <Button onClick={ResetExcluidos}>Reset</Button>
                    </Col>
                </Row>
            </div>
        </>
    )
}

export default RotinaTempEmail;
