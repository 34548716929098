import React from 'react'
import Style from './cardInscricoes.module.scss'
import CardSecure from '../CardSecure/cardSecure'

interface cardInscricoes{

}

const cardInscricoes:React.FC<cardInscricoes> = (props) =>{
    return(
        <>
            
            <div className={Style.card}>
                    <div className={Style.leftcard}>
                        <CardSecure titulo='Teste' cardArquivoUrl='https://cdn.motor1.com/images/mgl/q4GYR/s1/2022-honda-civic-touring.jpg'/>
                    </div>
                    <div className={Style.info}>
                            <div className={Style.top}>
                                <label>Situação do concurso: Teste;</label>   
                                <label>Etapa atual: Inscrição;</label>
                            </div> 
                            <div className={Style.bottom}>
                                <label>Situação do candidato: Apto;</label> 
                                <label>Total de inscritos: 490</label> 
                            </div>
                    </div>
            </div>
        </>
    )

}

export default cardInscricoes;
