import React, { useState, useEffect } from 'react'
import { Form, Input, Button, Select, message, notification, Checkbox, Tooltip, Popconfirm } from 'antd';
import { FormInstance } from 'antd/es/form';
import MaskedInput from 'react-input-mask';
import moment from 'moment';
import { isValidCPF } from '../../../utils/cpf';
import { DesenhoModel } from '../../../features/Models/desenho-model';
import Styles from '../root.module.scss';
import { useAppSelector, useAppDispatch } from '../../../features/hooks';
import { usuarioLogadoState } from '../../../features/slices/usuario-logado-slice';
import { InscricaoModel } from '../../../features/Models/inscricao-model';
import { increment, incrementPut, inscricaoState, postInscricao, putInscricao, clearResponse } from '../../../features/slices/inscricao-slice';
import { store } from '../../../features/store';
import { updateTypeAliasDeclaration } from 'typescript';
import "./style.module.less";
import { resolve } from 'path';
import { ehMenorQueIdadeAceita } from 'src/utils/validators';
import { InfoCircleOutlined } from '@ant-design/icons';
import classes from '*.module.css';

const { Option } = Select;

interface FormCategoria {
    ehEdicao: boolean,
    parentHandle: any,
    inscricaoId: any
    // dadosInscricao:InscricaoModel[] | undefined
}
const FormCategoria: React.FC<FormCategoria> = (props: FormCategoria) => {
    let formRef = React.createRef<FormInstance>();
    let pessoa = useAppSelector(usuarioLogadoState);
    const dispatch = useAppDispatch();
    let responseSlice = useAppSelector(inscricaoState);
    let inscricao = responseSlice.primeiraInscricao;
    const [erros, setErros] = useState<any>();
    const [form] = Form.useForm();
    const [tipoInst, setTipoInst] = useState<"SOCIEDADECIVIL" | "ESCOLA" | "">("");
    const [mask, setMask] = useState("(99) 99999-9999");
    const [check, setCheck] = useState(false);
    const [required, setRequired] = useState(true);
    const [openConfig, setOpenConfig] = useState(false);
    const [confirmed, setConfirmed] = useState(false);

    const [ehEdicao, setEhEdicao] = useState(false);
    let post = responseSlice.responsePost;

    const textoCheck = <span>Caso não estude e nem trabalhe, selecione essa opção.</span>

    useEffect(() => {          
        inscricao = responseSlice.primeiraInscricao;
        if (props.ehEdicao) {
            inscricao = responseSlice.inscricao
            onFill();
        }

        
    }, []);

    useEffect(() => {      
        inscricao = responseSlice.primeiraInscricao;
        if (props.ehEdicao) {
            inscricao = responseSlice.inscricao
            onFill();
        }
       
    }, [responseSlice]);

    useEffect(() => {
        if (responseSlice.responsePost != null) {
            if (responseSlice.responsePost.success) {
                openNotificationSuccess()
            }
            else {
                openNotification(responseSlice.responsePost.error[0])
            }
            if (confirmed) {
                proximaTab()
            }
        }
        if (post?.success) {
            setEhEdicao(true)
        }

    }, [responseSlice.responsePost]);

    useEffect(() => {
        if (responseSlice.responsePut != null) {
            if (responseSlice.responsePut.success) {
                proximaTab()
                message.success("Dados da Inscrição atualizados com sucesso")
            }
            else {
                openNotification(responseSlice.responsePut.error)
            }
        }
    }, [responseSlice.responsePut]);

    useEffect(() => {        
        return () => {
            dispatch(clearResponse());
        }
    }, [])

    const openNotificationSuccess = () => {
        const args = {
            message: 'Dados salvos com sucesso.',
            description: `Para finalizar sua inscrição, envie agora seu desenho e seu diário de bordo`,
            duration: 0,
        };
        notification.success(args);
    };


    const openNotificationConfirm = () => {
        notification.config({
            maxCount: 1
        })
        notification.warn({
            message: <strong>Confirme seus dados!</strong>,
            description: <p>Você não poderá alterar seus dados novamente confirme-os antes de continuar.</p>,
            duration: 0,
            style: { width: 700 },
            placement: 'top',
            onClose: () => setConfirmed(true),
        });
    };

    const onFinishFailed = (data: any) => {
        let res = [];
        res = data.errorFields.map((p: any) => p.name[0]);
        setErros(res);
    }

    const openNotification = (mensagem: string) => {
        const args = {
            message: 'Erro',
            description: mensagem,
            duration: 0,
        };
        notification.error(args);
    };

    const updateInst = () => {

        setTipoInst(formRef.current!.getFieldValue("instituicaoVinculacao"));
        return Promise.resolve();
    }

    const editar = (values: any) => {
        store.dispatch(putInscricao({ ...values, inscricaoId: props.inscricaoId }));
    }

    const proximaTab = () => {
        props.parentHandle("4");
    }

    const inscrever = async (request: any) => {
        await store.dispatch(postInscricao(request));        
    }

    const onUpdate = async () => {
        let values = formRef.current!.getFieldsValue();
        await dispatch(incrementPut(values));
        await editar({ ...inscricao, ...values });
        proximaTab();

    }

    const onFinish = async (values: any) => {
        await dispatch(increment(values));
        // if (props.ehEdicao) {
        //     await dispatch(incrementPut(values));
        // }
        let request = { ...inscricao, ...values }
        // if (props.ehEdicao) {
        //     editar({ ...inscricao, ...values });
        // } else {            
        await form.validateFields()
            .then(async (r: any)  => {
                if (confirmed) {
                    await inscrever(request);
                    proximaTab();
                }
                else {
                    openNotificationConfirm();
                }
            }).finally(() => {
            })
            .catch((error) => { })
        // }
    };

    const voltarTab = () => {
        props.parentHandle("2");

    }

    const onReset = () => {
        formRef.current!.resetFields();
    };

    const checkNaoPossui = (e: any) => {
        setRequired(!e.target.checked)
        if (e.target.checked) {
            formRef.current!.setFieldsValue({
                nomeInstituicaoVinculacao: null,
                telefoneInstituicaoVinculacao: null,
                categoria: "SEMVINCULO",
                setorPublico: false,
                cnpjInstituicaoVinculacao: null,
                serieEscola: null,
                cursoUniversidade: null,
            })
        }
        else {
            formRef.current!.setFieldsValue({
                categoria: "Selecione",
            })
        }
    };

    const onFill = () => {

        setTipoInst(inscricao.instituicaoVinculacao);
        //Informações do Inscrição Slice        
        formRef.current!.setFieldsValue({
            nomeInstituicaoVinculacao: inscricao?.nomeInstituicaoVinculacao,
            telefoneInstituicaoVinculacao: inscricao?.telefoneInstituicaoVinculacao,
            categoria: inscricao?.categoria,
            setorPublico: inscricao?.setorPublico,
            cnpjInstituicaoVinculacao: inscricao?.cnpjInstituicaoVinculacao,
            serieEscola: inscricao?.serieEscola,
            cursoUniversidade: inscricao?.cursoUniversidade,
            naoPossuiVinculo: inscricao?.naoPossuiVinculo,
        })
        if (inscricao?.naoPossuiVinculo) {
            setCheck(true)
            setRequired(false)
        }
    };

    return (
        <Form className={Styles.formulario} ref={formRef} form={form} name="control-ref" onFinish={onFinish}>
            <fieldset disabled={props.ehEdicao || ehEdicao}>
                {!ehMenorQueIdadeAceita(new Date(inscricao.dataNascimento), 17) ? <>
                    <Form.Item
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                        name="naoPossuiVinculo"
                        valuePropName="checked"
                    >
                        <Checkbox checked={check} onChange={(e) => { checkNaoPossui(e) }}> Não possuo Vínculo &nbsp;<Tooltip trigger="click" overlay={textoCheck} overlayClassName={Styles.Tooltip} placement="right" ><InfoCircleOutlined style={{ color: '#1890ff' }} /> </Tooltip></Checkbox>

                    </Form.Item>
                </> : null}

                <Form.Item
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    name="categoria"
                    label="Tipo Categoria"
                    rules={[{ required: true, message: "Campo Obrigatório" },
                    { validator: updateInst, validateTrigger: "onChange" }]}
                >

                    <Select
                        defaultValue='Selecione'
                        style={{ padding: '0.25em' }}
                        disabled={props.ehEdicao || !required}
                    >
                        <Option selected>Selecione</Option>

                        {ehMenorQueIdadeAceita(new Date(inscricao.dataNascimento), 17) ? <> <Option value="ESTUDANTE">Estudante</Option></> :
                            <><Option value="SOCIEDADECIVIL">Sociedade Civil</Option>
                                <Option value="ESTUDANTE">Estudante</Option>
                                <Option value="SEMVINCULO" hidden={!check} disabled>Sociedade Civil - Sem vínculo</Option></>
                        }

                    </Select>
                </Form.Item>

                <Form.Item
                    name="nomeInstituicaoVinculacao"
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    label="Nome da Instituição"
                    rules={[{ required: required, message: "Campo Obrigatório" }]}
                    hidden={!required}
                >
                    <Input className={Styles.item} disabled={!required} placeholder="Informe o nome da instituição"
                        title='Informe o nome da instituição' />
                </Form.Item>

                <Form.Item
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    name="setorPublico"
                    label="Setor"
                    rules={[{ required: required, message: "Campo Obrigatório" }]}
                    hidden={!required}

                >
                    <Select
                        defaultValue='Selecione'
                        disabled={props.ehEdicao || !required}
                    >
                        <Option selected>Selecione</Option>

                        <Option value={true}>Pública</Option>
                        <Option value={false}>Privado</Option>

                    </Select>
                </Form.Item>

                <Form.Item
                    name="telefoneInstituicaoVinculacao"
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    label="Telefone Instituição"
                    rules={[{ required: required, message: "Campo Obrigatório" }]}
                    hidden={!required}

                >
                    <MaskedInput className={Styles.item} mask={mask} disabled={!required}
                        onBlur={e => {
                            if (e.target.value.replace("_", "").length === 14) {
                                setMask("(99) 9999-9999");
                            }
                        }}
                        onFocus={e => {
                            if (e.target.value.replace("_", "").length === 14) {
                                setMask("(99) 99999-9999");
                            }
                        }} />
                </Form.Item>

                <Form.Item
                    labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}
                    name="cnpjInstituicaoVinculacao"
                    label="CNPJ"
                    hidden={!required}
                >
                    <MaskedInput mask="99.999.999/9999-99" placeholder={"__.___.___/____-__"} className={Styles.item} />
                </Form.Item>
            </fieldset>
            <div className={Styles.buttonsArea}>

                <Button type="primary" className={Styles.proximo} onClick={voltarTab}>
                    Voltar
                </Button>
                {props.ehEdicao || ehEdicao ? (<Button type="primary" className={Styles.proximo} onClick={proximaTab} >
                    Próximo
                </Button>
                ) :
                    (
                        <Button type="primary" className={Styles.proximo} htmlType={"submit"}>
                            Próximo
                        </Button>
                    )
                }

            </div>

        </Form>
    )
}

export default FormCategoria;
