import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import {ConcursoModel} from '../Models/concurso-model';
import {RootState} from '../store';
import ConcursoService from '../../services/Concurso-Service/concurso-service';

interface Termos{
    edital:boolean,
    termo:boolean
}

const initialState  = {
    edital:false,
    termo:false
} as Termos;


export const termosSlice = createSlice({
    name:"termos",
    initialState,
    reducers:{
        aceitarTermo: (state, action) => {
            state.termo = action.payload;
        },
        aceitarEdital: (state, action) =>{
            state.edital = action.payload;
        },
    }, 
    extraReducers:{}
    

});

export const {aceitarTermo,aceitarEdital} = termosSlice.actions;

export const termosState = (state: RootState) => state.termos;

export default termosSlice.reducer;