export const maskCpf = (input: string) => {
  if (input.length < 14)
    return input
      .replace(/\D/g, "")
      .replace(/(\d{3})(\d{1})/, "$1.$2")
      .replace(/(\d{3}\.{1}\d{3})(\d{1})/, "$1.$2")
      .replace(/(\d{3}\.{1}\d{3}\.{1}\d{3})(\d{1})/, "$1-$2")
}

export const maskCel = (input: string) => {
  return input
    .replace(/\D/g, "")
    .replace(/(\d{1})/, "($1")
    .replace(/(\d{2})(\d)/, "$1) $2")
    .replace(/(9?\d{4})(\d)/, "$1-$2")
    .replace(/(-\d{4})(\d+?)$/, "$1");
};

export const unMasked = (input: string) => {
  if (input) {
    return input
      .replace(/\D/g, "");
  }
  return '';

};
