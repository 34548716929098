import React, { useState } from 'react';
import { Form, Input, Button, message } from "antd";
import UserService from "../../../services/User-Service/user-service";
import Style from './reenvio-email.module.scss'
import UsuarioService from '../../../services/User-Service/user-service';
import Spinner from '../../components/Spinner/spinner-component';
import { useHistory } from 'react-router-dom';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import MaskedInput from 'react-input-mask';


const Reenvio: React.FC<any> = (prop) => {

    const [userInfo, setUserInfo] = useState<any>();

    const [load, setLoad] = useState<any>(false);

    const history = useHistory();

    const unMasked = (input: string) => {
        return input
            .replace(/\D/g, "");
    };

    const onFinish = async (values: any) => {

        setLoad(true);

        var data = {
            cpf: unMasked(values.cpf),
            senha: values.senha
        }
        // try {
        await UsuarioService.getByCpfSenha(data)
            .then(res => {
                (res.data)
                history.push({
                    pathname: '/cadastro',
                    state: { user: res.data.data }
                })
            })
            .finally(() => {
                setLoad(false);

            }).catch(error => message.error(error.response.data.error[0]));

    }

    return (
        <>
            <Spinner loading={load} />
            <div className={Style.container}>
                <h1 className={Style.titulo}>Reenviar e-mail de confirmação</h1>
                <Form
                    layout={'vertical'}
                    onFinish={onFinish}
                >
                    <Form.Item
                        name="cpf"
                        className={Style.FormName}
                        label="CPF"
                        rules={[{ required: true, message: "Campo Obrigatório" }]}
                    >
                        <MaskedInput
                            placeholder="Informe o CPF"
                            id="cpf"
                            className={`${Style.input} ant-input `} alwaysShowMask={false}
                            mask="999.999.999-99"
                        />
                    </Form.Item>
                    <Form.Item
                        name="senha"
                        className={Style.FormName}
                        label="Senha"
                        rules={[{ required: true, message: "Campo Obrigatório" }]}
                    >
                        <Input.Password
                            placeholder="Informe a Senha"
                            id="senha"
                            className={Style.input}
                            iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                        />
                    </Form.Item>
                    <Form.Item className={Style.inputButton}>
                        <Button htmlType="submit" id="confirmar-dados" className={Style.confirmButton}>
                            Confirmar dados
                        </Button>
                    </Form.Item>
                </Form>
            </div>
        </>
    )
}

export default Reenvio;