import { Form, Input, Button, message, notification } from "antd";
import UserService from "../../../services/User-Service/user-service";
import Style from './esqueci-senha.module.scss'
import MaskedInput from 'react-input-mask'
import { useForm } from "antd/es/form/Form";
import { isValidCPF } from "../../../utils/cpf";

const EsqueciSenha: React.FC<any> = (prop) => {
    const [form] = Form.useForm();

    const unMasked = (input: string) => {
        return input
            .replace(/\D/g, "");
    };

    const validarCPF = () => {
        var cpf = form.getFieldValue('cpf')
        if (!isValidCPF(cpf))
            message.error('CPF Inválido')

    }

    const openNotification = (email: string) => {
        const args = {
            message: 'Sucesso.',
            description: `Você receberá um e-mail para alteração de senha em ${email} para acessar o sistema`,
            duration: 0,
        };
        notification.success(args);
    };

    const onFinish = (values: any) => {
        values.cpf = unMasked(values.cpf)
        UserService.esqueciSenha(values).then((res) => {
            if (res.data.success)
                openNotification(values.email)
        }).catch((error) => {
            if (error.response != undefined) {
                error.response.data.error.map((a: any) => {
                    message.error(a)
                })
            }
            else {
                message.error('Erro na conexão com servidor')
            }
        })
    }
    return (
        <>
            <div className={Style.container}>
                <h1 className={Style.titulo}>Esqueci minha senha</h1>
                <Form
                    layout="vertical"
                    form={form}
                    onFinish={onFinish}
                >
                    <Form.Item name="cpf" label="CPF" className={Style.FormName} rules={[{ required: true, message: "Campo Obrigatório" }]}>

                        <MaskedInput className={Style.Inputs} alwaysShowMask={false} placeholder="Informe o CPF" onBlurCapture={() => { validarCPF() }} mask="999.999.999-99" />

                    </Form.Item>

                    <Form.Item name="email" label="E-mail" className={Style.FormName} rules={[{ required: true, message: "Campo Obrigatório" }]}>

                        <Input type="email" placeholder="Informe o E-mail" className={Style.Inputs} />

                    </Form.Item>
                    <Form.Item className={Style.inputButton}>
                        <Button htmlType="submit" id="confirmar-dados" className={Style.confirmButton}>
                            Confirmar dados
                        </Button>

                    </Form.Item>

                </Form>
            </div>
        </>
    )
}

export default EsqueciSenha;