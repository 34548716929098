import React from 'react';
import globalStyle from './styles/globalStyle.module.scss';
import "./styles/App.less";
import Routes from './Routes';
import Card from './public/components/Card/card-component';
import Footer from './public/components/Footer/footer-component';
import Header from './public/components/Header/header-component';

function App() {

  return (
    <div className={globalStyle.globalStyle}>
      <Routes />
    </div>
  );
}

export default App;
