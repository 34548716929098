import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import {PessoaModel} from '../Models/pessoa-model';
import {RootState} from '../store';
import PessoaService from '../../services/Pessoa-Service/pessoa-service';


interface Pessoa{
    pessoa: PessoaModel | null,
    loading: 'idle' | 'pending' | 'succeeded' | 'failed';
}

const initialState: Pessoa  = {
    pessoa: null,
    loading: 'idle'
};

export const getPessoa = createAsyncThunk('pessoa/getPessoa',async ()=>{
    return fetch('https://jsonplaceholder.typicode.com/posts').then((res)=> res.json());
})

export const pessoaSlice = createSlice({
    name:"pessoa",
    initialState,
    reducers:{
        falhar: (state) => {
            state.loading = "failed";
          },
    }, 
    extraReducers:(builder)=>{
        builder.addCase(getPessoa.pending, (state,action)=>{
            state.loading = 'pending'
        })
        builder.addCase(getPessoa.fulfilled, (state, {payload})=>{
            state.pessoa = payload;
            state.loading = 'succeeded';
        })
        builder.addCase(getPessoa.rejected,(state, action)=>{
            state.loading = 'failed';
        })
    }

});

export const pessoaState = (state: RootState) => state.pessoa.pessoa;

export const {falhar} = pessoaSlice.actions;

export default pessoaSlice.reducer;