import React from 'react';
import { useHistory, Redirect, useLocation } from 'react-router-dom';
import Styles from './votacao-popular.module.scss';
import { Button, Form, Image, Input, message, Modal, Card, Tooltip } from 'antd';
import { useEffect } from 'react';
import { RightOutlined, LeftOutlined, EditOutlined, UserOutlined, InfoCircleOutlined } from '@ant-design/icons'
import { useState } from 'react';
import FormItem from 'antd/lib/form/FormItem';
import MaskedInput from 'react-input-mask'
import { isValidCPF } from 'src/utils/cpf';
import VotoPopularService from 'src/services/VotoPopular-Service/votoPopular-service';
import { Document, Page, pdfjs } from 'react-pdf';
import { FilePdfFilled, FilePdfOutlined } from '@ant-design/icons';
import Logo from "../../../assets/LogoTexto.png";
import Spinner from 'src/public/components/Spinner/spinner-component';
import ModalidadeDesenhoService from 'src/services/ModalidadeDesenho-Service/modalidadeDesenho-service';
import Banner_1 from '../../../assets/agilita/texto.png';
import Banner_2 from '../../../assets/agilita/votacao_popular.png';
import concursoService from '../../../services/Concurso-Service/concurso-service';
import { ReactComponent as IconeEstudante } from '../../../assets/agilita/estudante_icon.svg';
import { ReactComponent as IconeSociedade } from '../../../assets/agilita/sociedade_icon.svg';

import Desenho_2_56 from '../../../assets/desenhos/Desenho_2_56_.png';
import Desenho_2_59 from '../../../assets/desenhos/Desenho_2_59_.png';
import Desenho_2_61 from '../../../assets/desenhos/Desenho_2_61_.png';
import Desenho_2_66 from '../../../assets/desenhos/Desenho_2_66_.png';
import Desenho_2_109 from '../../../assets/desenhos/Desenho_2_109_.png';
import Desenho_2_111 from '../../../assets/desenhos/Desenho_2_111_.png';
import Desenho_2_148 from '../../../assets/desenhos/Desenho_2_148_.png';
import Desenho_2_157 from '../../../assets/desenhos/Desenho_2_157_.png';
import Desenho_2_168 from '../../../assets/desenhos/Desenho_2_168_.png';
import Desenho_2_202 from '../../../assets/desenhos/Desenho_2_202_.png';
import Desenho_4_12 from '../../../assets/desenhos/Desenho_4_12_.png';
import Desenho_4_52 from '../../../assets/desenhos/Desenho_4_52_.png';
import Desenho_4_114 from '../../../assets/desenhos/Desenho_4_114_.png';
import Desenho_4_122 from '../../../assets/desenhos/Desenho_4_122_.png';
import Desenho_4_128 from '../../../assets/desenhos/Desenho_4_128_.png';
import Desenho_4_142 from '../../../assets/desenhos/Desenho_4_142_.png';
import Desenho_4_183 from '../../../assets/desenhos/Desenho_4_183_.png';
import Desenho_4_214 from '../../../assets/desenhos/Desenho_4_214_.png';
import Desenho_4_260 from '../../../assets/desenhos/Desenho_4_260_.png';
import Desenho_4_298 from '../../../assets/desenhos/Desenho_4_298_.png';
import axios from 'axios';
import { dominiosComuns } from './dominioEmailComum'

const VotoPopular: React.FC = (props) => {
    const history = useHistory();
    const location = useLocation();
    const [visibleArrowProx, setVisibleArrowProx] = useState<boolean>();
    const [visibleModalVoto, setVisibleModalVoto] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [form] = Form.useForm();
    const [desenhosFinalistas, setDesenhosFinalistas] = useState<any>();
    const [modalidades, setModalidades] = useState<any[]>([])
    const [modalDesenho, setModalDesenho] = useState<{ desenhoID: number, aberto: boolean }>({ desenhoID: 0, aberto: false })
    const [numPages, setNumPages] = useState()

    const desenhos = [
        {
            nome: "Desenho_2_56_.png",
            src: Desenho_2_56
        },
        {
            nome: "Desenho_2_59_.png",
            src: Desenho_2_59
        },
        {
            nome: "Desenho_2_61_.png",
            src: Desenho_2_61
        },
        {
            nome: "Desenho_2_66_.png",
            src: Desenho_2_66
        },
        {
            nome: "Desenho_2_109_.png",
            src: Desenho_2_109
        },
        {
            nome: "Desenho_2_111_.png",
            src: Desenho_2_111
        },
        {
            nome: "Desenho_2_148_.png",
            src: Desenho_2_148
        },
        {
            nome: "Desenho_2_157_.png",
            src: Desenho_2_157
        },
        {
            nome: "Desenho_2_168_.png",
            src: Desenho_2_168
        },
        {
            nome: "Desenho_2_202_.png",
            src: Desenho_2_202
        },
        {
            nome: "Desenho_4_12_.pdf",
            src: Desenho_4_12
        },
        {
            nome: "Desenho_4_52_.pdf",
            src: Desenho_4_52
        },
        {
            nome: "Desenho_4_114_.pdf",
            src: Desenho_4_114
        },
        {
            nome: "Desenho_4_122_.pdf",
            src: Desenho_4_122
        },
        {
            nome: "Desenho_4_128_.pdf",
            src: Desenho_4_128
        },
        {
            nome: "Desenho_4_142_.pdf",
            src: Desenho_4_142
        },
        {
            nome: "Desenho_4_183_.pdf",
            src: Desenho_4_183
        },
        {
            nome: "Desenho_4_214_.pdf",
            src: Desenho_4_214
        },
        {
            nome: "Desenho_4_260_.pdf",
            src: Desenho_4_260
        },
        {
            nome: "Desenho_4_298_.pdf",
            src: Desenho_4_298
        },
    ]
    pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;
    var modalidadesAux: any[] = [];

    useEffect(() => {

        concursoService.getbyconcursoid(process.env.REACT_APP_CONCURSO_MASCOTE_ID).then(res => {
            if (res) {
                let dataVotoInicio = new Date(res.dataVotacaoPopularInicio);
                let dataVotoFim = new Date(res.dataVotacaoPopularFim);
                let dataAtual = new Date();

                if (dataAtual >= dataVotoInicio && dataAtual <= dataVotoFim) {
                    carregarDesenhos();
                } else {
                    history.push("/redirect-educacaofiscal");
                }
            } else {
                history.push("/redirect-educacaofiscal");
            }
        });


    }, []);

    const carregarDesenhos = async (): Promise<void> => {
        setLoading(true)
        VotoPopularService.getDesenhoClassificadoPorCategoriaModalidade(process.env.REACT_APP_CONCURSO_MASCOTE_ID)
            .then((resp) => {
                setDesenhosFinalistas(resp.data)
                VerificaScroll(resp.data.length);
                ModalidadeDesenhoService.getModalidadeDesenhoByConcursoID(process.env.REACT_APP_CONCURSO_MASCOTE_ID)
                    .then((response) => {
                        modalidadesAux = response.data;
                        modalidadesAux.map((m: any) => {
                            m.categorias = []
                            resp.data.map((d: any) => {
                                if (d.modalidadeDesenhoID == m.modalidadeDesenhoID) {
                                    d.desenhos?.map((des: any) => {
                                        var x = desenhos.find(a => a.nome == des.nomeArquivo)
                                        des.srcDesenho = x?.src
                                    })
                                    m.categorias.push(d)
                                }
                            })
                        })
                        setModalidades(modalidadesAux)
                    }).finally(() => {
                        setLoading(false)
                    })
            }).finally(() => {

            })

    };

    const VerificaScroll: any = (total: number) => {

        for (let i = 0; i < total; i++) {

            const el = document.querySelector(`div#desenhoBox_${i}`);

            el?.addEventListener('scroll', function () {

                let elArrowR = document.querySelector(`div#right_${i}`);
                let elArrowL = document.querySelector(`div#left_${i}`);


                if (el.scrollLeft > 200) {

                    elArrowL?.setAttribute('style', 'visibility: visible;');
                    elArrowR?.setAttribute('style', 'visibility: hidden;');

                } else if (el.scrollLeft == 0) {

                    elArrowL?.setAttribute('style', 'visibility: hidden;');
                    elArrowR?.setAttribute('style', 'visibility: visible;');
                }
            })
        }
    }

    const onDocumentLoadSuccess = (n: any) => {
        setNumPages(n._pdfInfo.numPages)
    }

    const abrirModal = (desenhoID: number) => {
        if (desenhoID == modalDesenho.desenhoID && modalDesenho.aberto == true) {
            return true
        } else {
            return false
        }
    }

    // const votarDesenho = (values: any) => {
    //     setVisibleModalVoto(true)
    //     form.setFieldsValue({
    //         desenhoID: values.desenhoID,
    //     })
    // }

    const FormataGmail = (email: string) => {
        let v = email.split("@");
        if (v[1].toUpperCase().includes("GMAIL")) {
            let email = v[0].replaceAll(".", "");
            return `${email}@gmail.com`
        } else {
            return email;
        }
    }

    const ValidaTempEmail = async (email: string): Promise<any> => {
        return new Promise<any>(async (resolve, reject) => {
            setLoading(true);
            var dominio = email.split('@')[1]

            if (dominiosComuns.includes(dominio)) {
                resolve(true);
            }
            else {
                axios.get(`https://mailcheck.p.rapidapi.com/?domain=${dominio}`, {
                    headers: {
                        'Content-Type': 'application/json',
                        "Access-Control-Allow-Origin": "*",
                        "Access-Control-Allow-Headers": "Authorization",
                        "Access-Control-Allow-Methods": "GET, POST, OPTIONS, PUT, PATCH, DELETE",
                        "X-RapidAPI-Key": "03e2fe4cd0msh51e46b2e4d7d95bp1c69c3jsnab6b37d3e5df",
                        "X-RapidAPI-Host": "mailcheck.p.rapidapi.com",
                    }
                })
                    .then((response) => {
                        if (response.data.valid && !response.data.block && !response.data.disposable) {
                            resolve(true);
                        }
                        else {
                            resolve(false);
                        }
                    })
                    .catch((ex: any) => {
                        message.error('Falha na consulta de E-mail')
                        resolve(true);
                    })
                    .finally(() => {
                        setLoading(false);
                    })
            }
        });
    }

    const Submit = (values: any) => {
        setLoading(true)
        ValidaTempEmail(values.email).then((res) => {
            if (res) {
                VotoPopularService.postVotoPopular({
                    desenhoID: parseInt(values.desenhoID),
                    email: FormataGmail(values.email)
                }).then(() => {
                    message.success({
                        content: 'E-mail com link de confirmação enviado com sucesso. Acesse seu e-mail para validar seu voto.',
                        style: {
                            fontSize: '17px'
                        }
                    })
                }).catch((error) => {
                    if (error.response != undefined) {
                        error.response.data.error.map((a: any) => {
                            message.error(a)
                        })
                    }
                    else {
                        message.error('Erro na conexão com servidor')
                    }
                }).finally(() => {
                    setVisibleModalVoto(false);
                    setLoading(false)
                    form.resetFields();
                })
            }
            else {
                message.error("E-mail inválido")
            }
        })
    }

    const SubmitError = () => {
        form.resetFields();
    }

    const OnCancelModal = () => {
        setVisibleModalVoto(false);
        form.resetFields();
    }

    return (
        <>
            <Spinner loading={loading}></Spinner>
            <div className={Styles.container}>
                <div className={Styles.logoTitulo}>
                    <img src={Logo} className={Styles.logo} />
                    <img src={Banner_1} className={Styles.banner_1} />
                    <img src={Banner_2} className={Styles.banner_2} />
                </div>
                {modalidades.map((d: any, index: number) => {
                    return <>
                        <div className={Styles.modalidade}>
                            <p><b>MODALIDADE  {d.descricao.toUpperCase()}</b></p>
                        </div>
                        {d.categorias?.map((cat: any, index: number) => {
                            return <>

                                <div className={Styles.categoriaContainer}>
                                    <div className={Styles.iconArea}>
                                        {cat.categoriaNome == "Estudante" ? (<IconeEstudante className={Styles.icone_estudante} />) : (<IconeSociedade className={Styles.icone_sociedade} />)}
                                    </div>
                                    <div className={Styles.categoria}>
                                        <div className={Styles.categoriaDescricao}>
                                            <h4>Categoria:</h4>
                                            <p>{cat.categoriaNome.toUpperCase()}
                                                <Tooltip trigger="click" placement='bottomRight' title={cat.categoriaDescricao}>
                                                    <InfoCircleOutlined style={{ fontSize: '20px', color: 'black', marginLeft: '5px' }} />
                                                </Tooltip>
                                            </p>
                                        </div>
                                        <div className={Styles.divider}></div>
                                    </div>
                                </div>
                                <div className={Styles.lista} id={`desenhoBox_${index}`} >
                                    {cat.desenhos.map((a: any, index: any) => {
                                        // if (a?.contentType == '') {
                                        //     return (<>
                                        //         <div className={Styles.CardPdf}>
                                        //             <div className={Styles.placeholderPdf} onClick={() => { setModalDesenho({ desenhoID: a?.desenhoID, aberto: true }) }}>
                                        //                 <p style={{ textAlign: 'center' }}>Clique para visualizar o desenho</p>
                                        //                 <FilePdfOutlined style={{ fontSize: '30px' }} />
                                        //             </div>
                                        //             <Button onClick={() => votarDesenho(a)} className={Styles.ButtonVotar} >Votar</Button>
                                        //         </div>
                                        //         <Modal visible={abrirModal(a?.desenhoID)} onCancel={() => setModalDesenho({ desenhoID: a?.desenhoID, aberto: false })} onOk={() => setModalDesenho({ desenhoID: a?.desenhoID, aberto: false })} className={Styles.modalPdf} cancelText='Voltar' footer={[]}>
                                        //             <div className={Styles.pdfContainer}>
                                        //                 <Document
                                        //                     file={`data:application/pdf;base64,${a?.base64}`}
                                        //                     options={{ workerSrc: './pdf.worker.js' }}
                                        //                     onLoadSuccess={onDocumentLoadSuccess}
                                        //                 >
                                        //                     <Page className={Styles.pagePdf} pageNumber={1} height={500} width={600} />
                                        //                 </Document>
                                        //                 <div className={Styles.btnArea}>
                                        //                     <Button style={{ marginTop: '15px' }} type='primary' onClick={() => setModalDesenho({ desenhoID: a?.desenhoID, aberto: false })}>Voltar</Button>
                                        //                 </div>
                                        //             </div>
                                        //         </Modal>
                                        //     </>)
                                        // }
                                        // else {
                                        return (<div key={index} className={Styles.desenho} >
                                            <Image width={190} src={a.srcDesenho} height={200} style={{ borderRadius: '5px' }} />
                                            {/* <Button onClick={() => votarDesenho(a)} className={Styles.ButtonVotar}> Votar</Button> */}
                                        </div>)
                                    })}
                                    <div id={`left_${index}`} className={Styles.visibleArrowPrev}>
                                        <LeftOutlined />
                                    </div>
                                    <div id={`right_${index}`} className={Styles.visibleArrowProx}>
                                        <RightOutlined />
                                    </div>
                                </div>
                            </>

                        })}
                    </>
                })}
            </div>
            <Modal
                visible={visibleModalVoto}
                title="Digite seu e-mail"
                onCancel={OnCancelModal}
                footer={null}
                centered
                className={Styles.ModalVoto}
            >
                <div className={Styles.FormVoto}>
                    <p>Enviaremos um e-mail para confirmá-lo</p>
                    <p className={Styles.Spam}>Verifique sua caixa de SPAM</p>

                    <Form
                        form={form}
                        name="FormCadastro"
                        onFinish={Submit}
                        onFinishFailed={SubmitError}
                        layout={'vertical'}
                    >
                        <FormItem name="email" label="E-mail" className={Styles.FormName} rules={[{ required: true, message: "Campo Obrigatório" }]}>

                            <Input type="email" placeholder="Informe o E-mail" className={Styles.Inputs} />

                        </FormItem>

                        <FormItem name="desenhoID" label="desenhoId" hidden className={Styles.FormName} rules={[{ required: true, message: "Campo Obrigatório" }]}>

                            <Input type="number" className={Styles.Inputs} />

                        </FormItem>

                        <div className={Styles.Buttons}>

                            <Button htmlType='submit' className={Styles.ButtonVotar}>
                                Enviar e-mail de confirmação
                            </Button>

                        </div>
                    </Form>
                </div>

            </Modal>
        </>
    )
}

export default VotoPopular;
