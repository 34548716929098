import React, { useState, useEffect } from 'react';
import Header from '../../components/Header/header-component';
import Banner from '../../components/Banner/banner-component';
import SecaoConcurso from '../../components/SecaoConcurso/secaoconcurso-component';
import Styles from './concurso-page.module.scss';
import { useAppSelector, useAppDispatch } from '../../../features/hooks';
import { secaoConcursoState } from '../../../features/slices/secaoconcurso-slice';
import InscricaoButton from '../../components/InscricaoButton/inscricaobutton-component';
import ConcursoService from '../../../services/Concurso-Service/concurso-service';
import InscricaoService from '../../../services/Inscricao-Service/inscricao-service';
import UserService from '../../../services/User-Service/user-service';
import Spinner from '../../components/Spinner/spinner-component';
import AceiteButton from 'src/public/components/AceiteButton/aceiteButton-component';
import { termosState } from '../../../features/slices/termos-slice';
import moment from 'moment';
import { getUsuarioLogado, usuarioLogadoState } from 'src/features/slices/usuario-logado-slice';
import { store } from 'src/features/store';
import { Modal, Button } from 'antd';
import { ArrowDownOutlined, CaretDownOutlined } from '@ant-design/icons';

const Concurso: React.FC<any> = () => {

    // const secoes = useAppSelector(secaoConcursoState);
    const [concurso, setConcurso] = useState<any>({});
    const [inscricao, setInscricao] = useState<any>({});
    const [loading, setLoading] = useState<boolean>(false);
    const [currentCon, setCurrentCon] = useState<any>();
    const [termosAceitos, setTermosAceitos] = useState<boolean>(false);
    const termos = useAppSelector(termosState);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [usuarioLogado, setUsuarioLogado] = useState(false);
    const [foraPeriodoInscricao, setForaPeriodoInscricao] = useState(false);
    var usuario = useAppSelector(usuarioLogadoState);

    useEffect(() => {
        if (usuario?.aceiteEdital && usuario?.aceiteTermo)
            setTermosAceitos(true)
        ConcursoService.getConcursos().then(res => setCurrentCon(res.find((c: any) => c?.titulo === "Mascote Educação Fiscal")))


    }, [usuario])
    // useEffect(() => {
    //     setTermosAceitos(false);
    // }, [])

    useEffect(() => {
        var usuario = JSON.parse(sessionStorage.getItem('usuarioLogado') || "{}");
        if (usuario.nome == undefined) {
            setUsuarioLogado(false)
            if (termos.edital && termos.termo) {
                setTermosAceitos(true);
            } else {
                setTermosAceitos(false);
            }
        }
        else {
            setUsuarioLogado(true)
            if (usuario?.aceiteEdital && usuario?.aceiteTermo) {
                setTermosAceitos(true);
            } else {
                setTermosAceitos(false);
            }
        }
        setLoading(true)
        ConcursoService.getConcursos()
            .finally(() => setLoading(false))
            .then((concursos) => {
                if (validaDataInscricao(concursos[0])) {
                    setForaPeriodoInscricao(false)
                }
                else {
                    setForaPeriodoInscricao(true)
                }
                if (UserService.estaLogado() && concursos[0] != undefined && concursos[0] != null) {
                    setConcurso(concursos[0])
                    sessionStorage.setItem('ConcursoID', concursos[0].concursoID);
                    InscricaoService.getByUserIDConcursoID2(concursos[0].concursoID).then((res) => {
                        if (res.data == null) {
                            setIsModalVisible(true)
                        }
                        setInscricao(res.data)
                    })
                        .catch(() => setInscricao(null))
                }
                else {
                    setInscricao(null)
                }
            })
            .catch((err) => setInscricao(null))
    }, [termos])

    const validaDataInscricao = (concurso: any) => {
        var hoje = new Date();
        var dataFim = new Date(moment(concurso.dataInscricaoFim).toString());
        var dataInicio = new Date(moment(concurso.dataInscricaoInicio).toString());
        return dataFim > hoje && dataInicio < hoje
    }

    // return () => {
    //   window.removeEventListener("storage", checkTermos);
    // };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    return (
        <>
            <Spinner loading={loading}></Spinner>
            <div className={Styles.concursoPage}>
                <div className={Styles.tituloConcurso}><h1>Concurso para criação do (a) Mascote da Educação Fiscal <br />do Mato Grosso do Sul</h1></div>

                <Banner />

                <div className={Styles.info}>
                    <strong>Período de Inscrição e Envio dos Desenhos : {moment(currentCon?.dataInscricaoInicio).format('DD/MM/YYYY')} a {moment(currentCon?.dataInscricaoFim).format('DD/MM/YYYY')}</strong>
                </div>

                <AceiteButton foraPeriodoInscricao={foraPeriodoInscricao} />

                {/* <SecaoConcurso secoes={secoes} /> */}
                <InscricaoButton habilitado={termosAceitos} inscricao={inscricao} visible={isModalVisible} foraPeriodoInscricao={foraPeriodoInscricao} />
            </div>

            <Modal className={Styles.modal} title="Realizar Inscrição" visible={isModalVisible && usuarioLogado && !foraPeriodoInscricao} onCancel={handleCancel}
                footer={[<div style={{ textAlign: 'center' }}>
                    <ArrowDownOutlined className={Styles.iconModal} />
                </div>,
                <Button type="primary" onClick={handleCancel} className={Styles.buttonFooter} >Entendi</Button>
                ]}>
                <div className={Styles.contentModal}>
                    <p >Agora clique no botão abaixo 'Quero me inscrever' para realizar a sua inscrição</p>
                </div>

            </Modal>
        </>
    )
}

export default Concurso;
