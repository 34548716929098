import React from 'react'
import Styles from '../Footer/footer-component.module.scss'
import logo1 from '../../../assets/logo1.png'
import logo2 from '../../../assets/GOV-MS-horizontal-BRANCA.png'
import logo3 from '../../../assets/Logomarca_SETDIG_Branco.png'

import logo from '../../../assets/Logo.png'


const Footer:React.FC<any> = () =>{
  return (
    <>
        <footer className={Styles.footer}>
          <img src={logo} className={Styles.img}></img>
          <div className={Styles.imgs}>
            {/* <img src={logo1} className={Styles.img1}></img> */}
            <img src={logo3} className={Styles.img2}></img>
            <img src={logo2} className={Styles.img3}></img>
          </div>
        </footer>
    </>
  )
}

export default Footer;
