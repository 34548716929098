import React, { useEffect, useState } from 'react'
import { useLocation, Link } from 'react-router-dom'
import Style from './email-confirmado.module.scss'

import CodificarCaracteresEspeciaisQueryStrings from '../../../utils/codificar-caracteres-especiais-querystrings';
import UserService from '../../../services/User-Service/user-service';

import { unMasked } from '../../../utils/cpf';
import EmailConfirmadoComponent from 'src/public/components/Email-Confirmado/email-confirmado-component';


const EmailConfirmado: React.FC<any> = () => {
    let location = useLocation();
    const [html, setHtml] = useState<any>(<></>)
    const [loading, setLoading] = useState<boolean>(false)
    const [ocultar, setOcultar] = useState<boolean>(false)


    function enviarCpf(values: any) {
        let cpfUnmasked = unMasked(values.cpf)

        var cpfCriptografado: string = location.pathname.substring(18)
            .replaceAll('+', CodificarCaracteresEspeciaisQueryStrings.execByCaracter('+'))

        setLoading(true)

        UserService.putConfirmEmail(cpfCriptografado, cpfUnmasked)
            .finally(() => {
                setLoading(false)

            })
            .then((res: any) => {
                setHtml(<><span style={{textAlign:'center'}}>E-mail confirmado com sucesso!</span>
                <span style={{textAlign:'center'}}><p>Agora você pode logar!</p></span>                 
                    <div style={{textAlignLast:'center'}} className={Style.container}>
                        <Link to="/login"> Ir para a tela de Login </Link>
                    </div></>)
                setOcultar(true)
            }).catch((error) => {
                setHtml(<><span className={Style.emailConfirmadoDivError}>{error.response.data.error}</span></>);
            })
    }


    return (
        
            <EmailConfirmadoComponent
            
            loading = {loading}
            messageRequest = {html}
            methodOnFinish = {enviarCpf}
            labelCpf = {'Informe o CPF para confirmar seu cadastro'}
            ocultar  = {ocultar}
        
            ></EmailConfirmadoComponent>
    )
}
export default EmailConfirmado