import { DesenhoModel } from '../../features/Models/desenho-model';
import Api from '../index'

const DesenhoService = {
    getByDesenhoID : async (desenhoID:number):Promise<any> => {    
        return await Api.get(`/desenho/getdesenhobyid?desenhoid=${desenhoID}`).then((res:any)=>  {        
            return res.data});
    },
    
    getByInscricaoID : async (inscricaoID:number):Promise<any> => {    
        return await Api.get(`/desenho/getbyinscricaoid?inscricaoid=${inscricaoID}`).then((res:any)=>  {        
            return res.data});
    },

    post: async (modalidadeDesenhoID: number, inscricaoID: number, formData: any): Promise<any> => {
        return await Api.post(`desenho?inscricaoID=${inscricaoID}&modalidadeDesenhoID=${modalidadeDesenhoID}`,formData)
        .then((res) => { return res.data })                                       
    },

    delete: async (desenhoID:string): Promise<any> => {
        return await Api.delete(`desenho?id=${parseInt(desenhoID)}`).then((res) => { return res.data })
    },

    downloadDesenhoBase64ById: async (desenhoID:number): Promise<any> => {
        return await Api.get(`/desenho/downloaddesenhobase64byid?desenhoID=${desenhoID}`).then((res: any) => {
            return res.data
        });
    },

    downloadDiarioBase64ById: async (diarioID:number): Promise<any> => {
        return await Api.get(`/desenho/downloaddiariodebordobase64byid?diarioDeBordoID=${diarioID}`).then((res: any) => {
            return res.data
        });
    }
}

export default DesenhoService;