import React, { useEffect, useState } from 'react';
import { SecaoConcursoModel } from '../../../features/Models/secaoconcurso-model';
import { Button, Checkbox, message, Modal } from 'antd';
import { Document, Page } from 'react-pdf';
import './pdf.css';
import pdf from "../../../assets/edital/editalBase64pdf";
import termo from "../../../assets/edital/termoBase64pdf";
import aditivo from "../../../assets/edital/aditivoBase64pdf";
import aditivo2 from "../../../assets/edital/aditivoBase64pdf2";
import './style.less'
import Styles from './aceiteButton-component.module.scss';
import UserService from 'src/services/User-Service/user-service';
import { store } from 'src/features/store';
import { aceitarTermo, aceitarEdital, termosState } from '../../../features/slices/termos-slice';
import { getUsuarioLogado, usuarioLogadoState } from 'src/features/slices/usuario-logado-slice';
import { useAppSelector, useAppDispatch } from 'src/features/hooks';
import { CheckOutlined, FileDoneOutlined, FileOutlined } from '@ant-design/icons';

interface AceiteButtonProps {
    foraPeriodoInscricao: boolean
}

const AceiteButton: React.FC<AceiteButtonProps> = (props) => {
    const [usuarioLogado, setUsuarioLogado] = useState<any>();
    const [hiddenAceite, setHiddenAceite] = useState(false);
    const [foraPeriodoInscricao, setForaPeriodoInscricao] = useState(false);

    const [isModalVisibleEdital, setIsModalVisibleEdital] = useState(false);
    const [base64Edital, setBase64Edital] = useState("");
    const [numPagesEdital, setNumPagesEdital] = useState(null);
    const [disableEdital, setDisableEdital] = useState(false);
    const [visibleCheckEdital, setVisibleCheckEdital] = useState(false);
    const [checkEdital, setCheckEdital] = useState<boolean>(false);
    const [buttonEdital, setButtonEdital] = useState(false);

    const [isModalVisibleTermo, setIsModalVisibleTermo] = useState(false);
    const [base64Termo, setBase64Termo] = useState("");
    const [numPagesTermo, setNumPagesTermo] = useState(null);
    const [disableTermo, setDisableTermo] = useState(false);
    const [visibleCheckTermo, setVisibleCheckTermo] = useState(false);
    const [checkTermo, setCheckTermo] = useState<boolean>(false);
    const [buttonTermo, setButtonTermo] = useState(false);
    const termos = useAppSelector(termosState);

    const [base64Aditivo, setBase64Aditivo] = useState("");
    const [base64Aditivo2, setBase64Aditivo2] = useState("");

    const [numPagesAditivo, setNumPagesAditivo] = useState(null);

    const dispatch = useAppDispatch();
    var usuario = useAppSelector(usuarioLogadoState);

    useEffect(() => {
        setForaPeriodoInscricao(props.foraPeriodoInscricao)
    }, [props.foraPeriodoInscricao])

    useEffect(() => {
        setBase64Edital(`data:application/pdf;base64,${pdf}`);
        setBase64Termo(`data:application/pdf;base64,${termo}`);
        setBase64Aditivo(`data:application/pdf;base64,${aditivo}`);
        setBase64Aditivo2(`data:application/pdf;base64,${aditivo2}`)

        setCheckEdital(termos.edital);
        setCheckTermo(termos.termo);

        //Definindo o aceito como default sem necessidade de login
        if (usuario?.nome != undefined) {
            // setHiddenAceite(false)
            UserService.getByID({ userID: usuario.id }).then((e) => {
                setUsuarioLogado(e.data.data);
                setCheckEdital(e.data.data.aceiteEdital)
                setCheckTermo(e.data.data.aceiteTermo)
            })
        }
        else {
            setHiddenAceite(false)
        }

    }, [usuario, termos]);

    const showModal = (value: string) => {
        if (value == "edital") {
            setDisableEdital(true)
            setIsModalVisibleEdital(true)
        }
        if (value == "termo") {
            setDisableTermo(true)
            setIsModalVisibleTermo(true)
        }
    };

    const onDocumentLoadSuccessEdital = (n: any) => {
        setNumPagesEdital(n._pdfInfo.numPages)
    }

    const onDocumentLoadSuccessAditivo = (n: any) => {
        setNumPagesAditivo(n._pdfInfo.numPages)
    }

    const aceitar = (value: string) => {
        if (value == "edital") {

            if (checkEdital) {
                //     UserService.aceitarEditalTermo({ userID: usuarioLogado.id, aceiteEdital: checkEdital, aceiteTermo: checkTermo })
                //         .then((e) => {
                //             if (e.data.success) {
                //                 sessionStorage.setItem("usuarioLogado", JSON.stringify(e.data.data));
                //                 setUsuarioLogado(e.data.data)
                //             }
                //             else {
                //                 message.error("Falha na conexão com servidor")
                //             }
                //         })
                //         .finally(() => {
                //             store.dispatch(getUsuarioLogado());
                //             setIsModalVisibleEdital(false)
                //         })
                dispatch(aceitarEdital(true));
                setIsModalVisibleEdital(false);

            }
            else {
                message.error("É necessário declarar que está de acordo com os termos do regulamento do edital antes de Aceitar")
            }
        }

        if (value == "termo") {
            if (checkTermo) {
                dispatch(aceitarTermo(true));
                setIsModalVisibleTermo(false);

                // UserService.aceitarEditalTermo({ userID: usuarioLogado.id, aceiteEdital: checkEdital, aceiteTermo: checkTermo })
                //     .then((e) => {
                //         if (e.data.success) {
                //             sessionStorage.setItem("usuarioLogado", JSON.stringify(e.data.data));
                //             setUsuarioLogado(e.data.data)
                //         }
                //         else {
                //             message.error("Falha na conexão com servidor")
                //         }
                //     })
                //     .finally(() => {
                //         store.dispatch(getUsuarioLogado());
                //         setIsModalVisibleTermo(false)
                //     })
            }
            else {
                message.error("É necessário declarar ter ciência do termo de autorização de uso antes de Aceitar")
            }
        }
    };

    const handleCancelEdital = () => {

        // if (!checkEdital) {
        //     message.error("Leia o termo do edital para realizar o aceite.")
        // }
        // else {
        setIsModalVisibleEdital(false);
        setDisableEdital(false)
        // }
    };

    const onScrolllEdital = (e: any) => {
        var target = e.target

        var tamanho = target.scrollHeight - target.scrollTop;

        var upClientHigh = target.clientHeight + 10;
        var downClientHigh = target.clientHeight - 10;

        if (tamanho <= upClientHigh && tamanho >= downClientHigh) {
            setVisibleCheckEdital(true)
            setDisableEdital(false)
        }
        else {
            setVisibleCheckEdital(false)
            setDisableEdital(true)
        }
    }

    const aceiteEdital = (e: any) => {
        setDisableEdital(!e.target.checked)
        setCheckEdital(e.target.checked)
        dispatch(aceitarEdital(e.target.checked));

    }

    /*Termo*/
    const onDocumentLoadSuccessTermo = (n: any) => {
        setNumPagesTermo(n._pdfInfo.numPages)
    }

    const handleCancelTermo = () => {
        // if (!checkTermo) {
        //     message.error("Leia o termo de autorização para realizar o aceite.")
        // }
        // else {
        setIsModalVisibleTermo(false);
        setDisableTermo(false)
        // }
    };

    const onScrolllTermo = (e: any) => {

        var target = e.target

        var tamanho = target.scrollHeight - target.scrollTop;

        var upClientHigh = target.clientHeight + 10;
        var downClientHigh = target.clientHeight - 10;


        if (tamanho <= upClientHigh && tamanho >= downClientHigh) {
            setVisibleCheckTermo(true)
            setDisableTermo(false)
            // setDisableTermo(false)

        }
        else {
            setVisibleCheckTermo(false)
            setDisableTermo(true)
        }
    }

    const aceiteTermo = (e: any) => {
        setDisableTermo(!e.target.checked)
        setCheckTermo(e.target.checked)
        dispatch(aceitarTermo(e.target.checked));
    }

    return (
        <>
            <div className={Styles.buttons}>
                <button className={checkTermo ? Styles.buttonAberto : Styles.buttonAbrir} onClick={() => { showModal("termo") }} disabled={disableEdital}> Ler Termo de Autorização de Uso {checkTermo ? <><FileOutlined /> <CheckOutlined style={{ color: 'rgb(100, 240, 82)' }} /></> : <FileOutlined />}</button>
            </div>
            <div className={Styles.buttons}>
                <button className={checkEdital ? Styles.buttonAberto : Styles.buttonAbrir} onClick={() => { showModal("edital") }} disabled={disableEdital}> Ler Termo do Regulamento do Concurso {checkEdital ? <><FileOutlined /> <CheckOutlined style={{ color: 'rgb(100, 240, 82)' }} /></> : <FileOutlined />}</button>
            </div>
            {/* Modal Edital */}
            <Modal className={Styles.modal} title="Termo do Regulamento do Concurso" visible={isModalVisibleEdital} onCancel={handleCancelEdital}
                footer={[
                    <div className={Styles.buttonsAceite}>
                        {foraPeriodoInscricao ? null : <Checkbox checked={checkEdital} disabled={!visibleCheckEdital} onChange={(e) => { aceiteEdital(e) }}> Declaro ter ciência e estar de acordo com o termo acima</Checkbox>},
                        {foraPeriodoInscricao ? null : <Button type="primary" onClick={() => aceitar("edital")} disabled={disableEdital}>Aceitar</Button>}
                    </div>
                ]}>
                <div className={Styles.pdfContainer} id="pdfContainer" onScroll={(e) => { onScrolllEdital(e) }}>

                    <Document
                        className={Styles.pdf}
                        file={base64Edital}
                        options={{ workerSrc: "./pdf.worker.js" }}
                        onLoadSuccess={onDocumentLoadSuccessEdital}
                    >
                        {Array.from(new Array(numPagesEdital), (el, index) => (
                            <Page key={`page_${index + 1}`} pageNumber={index + 1} />
                        ))}
                    </Document>
                    <div className="ant-modal-header">
                        <h1 className="ant-modal-title"><strong>Aditivo dos itens nº: 6, 14, 15 e 17.</strong> </h1>
                    </div>
                    <Document
                        className={Styles.pdf}
                        file={base64Aditivo}
                        options={{ workerSrc: "./pdf.worker.js" }}
                        onLoadSuccess={onDocumentLoadSuccessAditivo}
                    >
                        {Array.from(new Array(numPagesAditivo), (el, index) => (
                            <Page key={`page_${index + 1}`} pageNumber={index + 1} />
                        ))}
                    </Document>
                    <div className="ant-modal-header">
                        <h1 className="ant-modal-title"><strong>Prorrogação do período de inscrição.</strong> </h1>
                    </div>
                    <Document
                        className={Styles.pdf}
                        file={base64Aditivo2}
                        options={{ workerSrc: "./pdf.worker.js" }}
                        onLoadSuccess={onDocumentLoadSuccessAditivo}
                    >
                        {Array.from(new Array(numPagesAditivo), (el, index) => (
                            <Page key={`page_${index + 1}`} pageNumber={index + 1} />
                        ))}
                    </Document>

                </div>

            </Modal>

            {/* Modal Termo */}
            <Modal className={Styles.modal} title="Termo de Autorização de Uso" visible={isModalVisibleTermo} onCancel={handleCancelTermo}
                footer={[
                    <div className={Styles.buttonsAceite}>
                        {foraPeriodoInscricao ? null : <Checkbox checked={checkTermo} disabled={!visibleCheckTermo} onChange={(e) => { aceiteTermo(e) }}> Declaro ter ciência e estar de acordo com o termo acima</Checkbox>},
                        {foraPeriodoInscricao ? null : <Button type="primary" onClick={() => aceitar("termo")} disabled={disableTermo}>Aceitar</Button>}
                    </div>
                ]}>
                <div className={Styles.pdfContainer} id="pdfContainer" onScroll={(e) => { onScrolllTermo(e) }}>

                    <Document
                        className={Styles.pdf}
                        file={base64Termo}
                        options={{ workerSrc: "./pdf.worker.js" }}
                        onLoadSuccess={onDocumentLoadSuccessTermo}
                    >
                        {Array.from(new Array(numPagesTermo), (el, index) => (
                            <Page key={`page_${index + 1}`} pageNumber={index + 1} />
                        ))}
                    </Document>

                </div>

            </Modal>
        </>
    );
}

export default AceiteButton;
