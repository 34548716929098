import React, { useState, useEffect } from 'react'
import { Form, Input, Button, Select, notification, Row, Col, Popover, Modal, Checkbox, Tooltip } from 'antd';
import { FormInstance } from 'antd/es/form';
import MaskedInput from 'react-input-mask';
import moment from 'moment';
import { useAppSelector } from '../../../features/hooks';
import { isValidCPF } from '../../../utils/cpf';
import { DesenhoModel } from '../../../features/Models/desenho-model';
import Styles from '../root.module.scss';
import { usuarioLogadoState } from '../../../features/slices/usuario-logado-slice';
import DesenhoService from '../../../services/Desenho-Service/desenho-service';
import { Upload, Popconfirm, message, Spin, Table } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { inscricaoState } from '../../../features/slices/inscricao-slice';
import { useHistory, useParams } from 'react-router-dom';
import style from './FormUpload-component.module.scss';
import ModalidadeDesenhoService from '../../../services/ModalidadeDesenho-Service/modalidadeDesenho-service';
import InscricaoService from 'src/services/Inscricao-Service/inscricao-service';
import './styles.module.less'



interface FormUpload {
    parentHandle: any,
    ehEdicao: boolean
}

const FormUpload: React.FC<FormUpload> = (props: FormUpload) => {

    const [form] = Form.useForm();
    const [fileDesenho, setFileDesenho] = useState<any>();
    const [fileDiarioDeBordo, setFileDiarioDeBordo] = useState<any>();
    const [loadingButtonUpload, setLoadingButtonUpload] = useState(false);
    const [loadingArquivos, setLoadingArquivos] = useState(false);
    const [arquivos, setArquivos] = useState<{ key: number, descricaoModalidade: string, diarioDeBordo: any, desenho: DesenhoModel }[]>([]);
    let responseSlice = useAppSelector(inscricaoState);
    const [modalidadeDesenho, setModalidadeDesenho] = useState<any>([]);
    const [modalidadeDesenhoSelecionada, setModalidadeDesenhoSelecionada] = useState<any>();
    const [typePermitido, setTypePermitido] = useState(false);
    let { id } = useParams<any>();
    const [inscricaoID, setInscricaoID] = useState(0);
    let history = useHistory();
    const [textoUpload, setTextoUpload] = useState<any>();
    const [textoDiario, setTextoDiario] = useState<any>();
    const [loading, setLoading] = useState<boolean>(false)

    useEffect(() => {
        if (responseSlice.responsePost != null && responseSlice.responsePost.success) {
            setLoadingArquivos(false)
            setInscricaoID(responseSlice.responsePost.data);
        }
        if (props.ehEdicao) {
            setInscricaoID(id)
            setLoadingArquivos(true)
            DesenhoService.getByInscricaoID(id)
                .finally(() => setLoadingArquivos(false))
                .then(response => {
                    let arquivosResponse: any[] = [];
                    response.map((d: any) => {

                        arquivosResponse.push({
                            key: d.desenhoID,
                            descricaoModalidade: d.modalidadeDesenho.descricao,
                            diarioDeBordo: d.diarioDeBordo,
                            desenho: d
                        })

                    })
                    setArquivos(arquivosResponse)
                })
        }
    }, [responseSlice])

    useEffect(() => {
        ModalidadeDesenhoService.getModalidadeDesenho().then((res) => {
            setModalidadeDesenho(res.data)
        });

    }, [])
    const layout = {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 },
    };
    const tailLayout = {
        wrapperCol: { offset: 8, span: 16 },
    };

    let formRef = React.createRef<FormInstance>();

    const voltarTab = () => {
        props.parentHandle("3");

    }
    const colunas = [
        {
            title: 'Modalidade',
            dataIndex: 'descricaoModalidade',
            key: 'descricaoModalidade',
            width: '70%'
        },
        {
            title: 'Ação',
            dataIndex: '',
            key: 'x',
            render: (text: any, record: any) => (
                <>
                    <Popconfirm placement="top" title="Você tem certeza que deseja excluir o desenho junto ao seu diário de bordo?" onConfirm={(e) => { onDelete(record) }} okText="Sim" cancelText="Não">
                        <Button style={{ marginBottom: '5px' }} danger>  Excluir arquivos</Button>
                    </Popconfirm>
                    <Popover
                        content={
                            <>
                                <Button onClick={(e: any) => { baixarArquivoDesenho(record.key, record.desenho.contentType, record.desenho.nomeArquivo) }}>Baixar desenho </Button>
                                <Button onClick={(e: any) => { baixarArquivoDiarioDeBordo(record.key, record.diarioDeBordo.contentType, record.diarioDeBordo.nomeArquivo) }}>Baixar diário de bordo </Button>
                            </>
                        }
                        // title="Title"
                        trigger="click"
                    // visible={this.state.visible}
                    // onVisibleChange={this.handleVisibleChange}
                    >
                        <Button>Baixar arquivos</Button>
                    </Popover>
                </>
            ),
            width: '30%'
        }
    ]

    const baixarArquivoDesenho = (desenhoID: number, contentType: string, nomeArquivo: string) => {
        setLoadingArquivos(true);

        DesenhoService.downloadDesenhoBase64ById(desenhoID)
            .then((response: any) => {

                if (response !== null && contentType != null) {
                    var link = document.createElement('a');
                    link.innerHTML = 'Download file';
                    link.download = nomeArquivo;
                    link.href = 'data:' + contentType + ';base64,' + response?.result
                    link.style.visibility = "hidden";
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                }
            })
            .catch((err: any) => {
                message.error('Falha na requisição')

            }).finally(() => {
                setLoadingArquivos(false);
            })

    }
    const baixarArquivoDiarioDeBordo = (diarioID: number, contentType: string, nomeArquivo: string) => {
        setLoadingArquivos(true);

        DesenhoService.downloadDiarioBase64ById(diarioID)
            .then((response: any) => {

                if (response !== null && contentType != null) {
                    var link = document.createElement('a');
                    link.innerHTML = 'Download file';
                    link.download = nomeArquivo;
                    link.href = 'data:' + contentType + ';base64,' + response?.result
                    link.style.visibility = "hidden";
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                }
            })
            .catch((err: any) => {
                message.error('Falha na requisição')

            }).finally(() => {
                setLoadingArquivos(false);
            })

    }

    const openNotification = () => {
        const args = {
            message: 'Inscrição realizada com sucesso!',
            description: `Acompanhe sua inscrição.`,
            duration: 0,
        };
        notification.success(args);
    };


    const finalizarInscricao = () => {

        setLoading(true)
        InscricaoService.finalizarInscricao(inscricaoID).then(() => {
            history.push(`/protocolo-inscricao/${inscricaoID}`)
            openNotification();
        })
            .finally(() => setLoading(false))
            .catch((data) => { message.error(data.response?.data.error[0] != undefined ? data.response.data.error[0] : "falha na conexão com o servidor") })
    }

    const uploadRequest = async () => {

        if (fileDesenho !== undefined && form.getFieldValue('modalidadeDesenho') !== undefined
            && fileDiarioDeBordo != undefined) {

            handleUploadDesenho({ fileList: [{}] })
            handleUploadDiarioDeBordo({ fileList: [{}] });
            setFileDesenho(false);
            setFileDiarioDeBordo(false);
            let formData = new FormData();
            formData.append("desenhoFile", fileDesenho.originFileObj);
            var modalidadeDesenhoIDNovoDesenho = form.getFieldValue('modalidadeDesenho');
            formData.append("diarioDeBordoFile", fileDiarioDeBordo.originFileObj);

            setLoadingButtonUpload(true);
            await DesenhoService
                .post(modalidadeDesenhoIDNovoDesenho, inscricaoID, formData)
                .finally(() => { setLoadingButtonUpload(false); })
                .then(res => {
                    var array = arquivos.slice();
                    array.unshift({
                        key: res?.data?.desenhoID,
                        desenho: res?.data,
                        descricaoModalidade: res?.data?.modalidadeDesenho?.descricao,
                        diarioDeBordo: res?.data?.diarioDeBordo
                    })
                    setArquivos(array);
                    message.success("Salvo com sucesso!")
                })
                .catch(err => {
                    message.error(err.response.data.error)

                });
        }
        else { message.error("Erro! Tente anexar os arquivos novamente.") }

    }

    const handleUploadDesenho = (e: any) => {
        setFileDesenho(e.fileList[0]);
    }

    const handleUploadDiarioDeBordo = (e: any) => {
        setFileDiarioDeBordo(e.fileList[0]);
    }

    const onDelete = async (record: any) => {
        setLoadingArquivos(true);
        DesenhoService.delete(record.key)
            .then(res => {

                setArquivos(state => state.filter((item) => { return item.key !== record.key })
                )
                message.success("Removido com sucesso");
            })
            .catch(err => {
                message.error("Falha na remoção");
            }).finally(() => {

                setLoadingArquivos(false);
            })

    }

    const onFinish = (values: any) => {

        // InscricaoService.post(values).then((res)=> {        
        // })
    };

    const selecionarModalidade = (modalidadeID: any) => {
        var modalidade = modalidadeDesenho.find((e: any) => e.modalidadeDesenhoID == modalidadeID)
        
        setModalidadeDesenhoSelecionada(modalidade);

        let ContentTypes = modalidade.contentTypes;
        
        let arquivo = ContentTypes.split("/");

        setTextoDiario(<span style={{fontSize:'15px'}}>&#8226; Instrumento utilizado para registrar os acontecimentos mais importantes no desenvolvimento de
        suas atividades.  Diário de bordo, é um documento com reflexões e comentários sobre o andamento do trabalho que se está desenvolvendo. (Arquivo no formato PDF)</span>)
        setTextoUpload(<span style={{fontSize:'15px'}}>&#8226; Formato de arquivo permitido: {arquivo[1]}</span>)
        
    };
    
    return (
        
        <div className={Styles.formulario}>
            <h4><b>Envio dos desenhos</b></h4>
            <span>Só pode ser enviado um desenho por modalidade</span>
            <hr />

            <Form
                form={form}
                {...layout}
                ref={formRef}
                name="control-ref" onFinish={uploadRequest}>
                <br />
                <label>
                    <><span style={{ color: 'red' }}>*</span> <b>Modalidade do envio do desenho:</b>
                        <p style={{ fontWeight: "normal" }}>(Você pode se inscrever em mais de uma modalidade)</p></>
                </label>
                <Form.Item name="modalidadeDesenho"
                    labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}
                    rules={[{ required: true, message: 'Campo obrigatório' }]}
                    style={{ fontWeight: "bold" }}
                >

                    <Select
                        defaultValue='selecione'
                        placeholder="Selecione uma modalidade" 
                        onSelect={(e: any) => { selecionarModalidade(e) }}>
                        <Select.Option value="selecione" selected>Selecione</Select.Option>
                        {modalidadeDesenho ? modalidadeDesenho?.map((option: any, index: any) => {
                            return <Select.Option key={index}
                                disabled={
                                    (!responseSlice?.inscricao?.instituicaoVinculacao?.includes("ESCOLA") &&
                                        option.descricao.includes("Estudante")) ||
                                        (responseSlice?.inscricao?.instituicaoVinculacao?.includes("ESCOLA") &&
                                            option.descricao.includes("Sociedade"))
                                        ? true : false}
                                value={option.modalidadeDesenhoID}>
                                {option.descricao}
                            </Select.Option>;
                        }
                        ) : null}
                    </Select>
                </Form.Item>

                {modalidadeDesenhoSelecionada ? <Row className={`${Styles.flex} ${Styles.justifyContentSpaceBetween} ${Styles.alignItemsCenter}`}>
                    <Col span={8}>
                        <Form.Item name="arquivo"
                            rules={[{ required: true, message: 'Por favor anexe uma arquivo com o desenho!' }]}
                        >
                            <Upload beforeUpload={(e => {
                                var valid = e.type != "" && modalidadeDesenhoSelecionada.contentTypes.includes(e.type);
                                if (!valid) {
                                    message.error(`${e.name} não esta em um formato permitido`)
                                    return Upload.LIST_IGNORE;
                                }

                                setTypePermitido(valid)
                                return !valid;
                            })}
                                showUploadList={fileDesenho ? true : false} onChange={(e) => { handleUploadDesenho(e) }} name="arquivo" maxCount={1} className={style.UploadName}>
                                <Tooltip overlay={textoUpload} placement="bottomLeft">
                                    <Button ghost type="primary" icon={<UploadOutlined />}>Anexar desenho</Button>
                                </Tooltip>
                            </Upload>
                        </Form.Item>
                    </Col>

                    <Col span={10} offset={6}>
                        <Form.Item
                            rules={[{ required: true, message: 'Por favor anexe uma arquivo com o diário de bordo!', }]}
                            name="diarioDeBordo" >
                            <Upload beforeUpload={e => {
                                var valid = e.type != "" && e.type == 'application/pdf';
                                if (!valid) {
                                    message.error(`${e.name} não esta em um formato permitido`)
                                    return Upload.LIST_IGNORE;
                                }

                                return !valid;
                            }}
                                showUploadList={fileDiarioDeBordo ? true : false} onChange={(e) => handleUploadDiarioDeBordo(e)} name="diarioDeBordo" maxCount={1} className={style.UploadName}>
                                <Tooltip overlay={textoDiario} placement="bottomLeft">
                                    <Button ghost type="primary" icon={<UploadOutlined />}>Anexar diário de bordo</Button>
                                </Tooltip>
                            </Upload>
                        </Form.Item>
                    </Col>

                </Row> : null}

                {/* <Row className={`${Styles.flex} ${Styles.justifyContentSpaceBetween} ${Styles.alignItemsCenter}`}> */}

                {/* </Row> */}
                <Form.Item name="arquivo" >
                    <br />
                    <Button loading={loadingButtonUpload} htmlType="submit" type="primary">Salvar anexos </Button>
                </Form.Item>
            </Form>
            <Spin spinning={loadingArquivos}>
                <Table dataSource={arquivos} columns={colunas} pagination={{ pageSize: 1 }} scroll={{ x: 100 }} />
            </Spin>
            <div className={Styles.buttonsArea}>
                <Button type="primary" className={Styles.proximo} onClick={voltarTab}>
                    Voltar
                </Button>
                <Popconfirm placement="top" title={<div><p>Atenção</p><p>Você pode finalizar sua  inscrição com desenhos em uma </p> <p>ou duas modalidades.</p>  <p>Caso já tenha escolhido sua(s) modalidade(s) desejada(s), </p>  <p>clique no botão Finalizar para concluir sua inscrição, </p>  <p>ou clique em cancelar para continuar sua inscrição, agora se </p> <p>preferir, pode clicar no botão cancelar, para continuar depois.</p> </div>} onConfirm={(e) => { finalizarInscricao() }} okText="Finalizar" cancelText="Cancelar" className={Styles.popup}>
                    <Button loading={loading} type="primary" className={Styles.finalizar} htmlType="submit">
                        Finalizar Inscrição
                    </Button>
                </Popconfirm>
            </div>
            <br />

        </div>
    )
}

export default FormUpload;
