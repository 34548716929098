import Api from '../index'

const ModalidadeDesenhoService = {

    getModalidadeDesenho: async (): Promise<any> => {
        return await Api.get(`/modalidadeDesenho`).then((res: any) => {
            return res.data
        });
    },
    getModalidadeDesenhoByConcursoID: async (concursoID: string | undefined) => {
        return await Api.get(`/modalidadeDesenho/getbyconcursoid?concursoID=${concursoID}`).then((res: any) => {
            return res.data
        })
            .catch((err) => { return false })
    },
}


export default ModalidadeDesenhoService;