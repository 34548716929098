import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import concursoReducer from './slices/concurso-slice';
import pessoaReducer from './slices/pessoa-slice';
import secaoConcursoReducer from './slices/secaoconcurso-slice';
import usuarioLogadoReducer from './slices/usuario-logado-slice';
import inscricaoReducer from './slices/inscricao-slice';
import termosReducer from './slices/termos-slice';
import storage from 'redux-persist/lib/storage'
import {combineReducers} from "redux"; 
import { persistReducer } from 'redux-persist'


// const reducers = combineReducers({
//   concurso: concursoReducer,
//   pessoa: pessoaReducer,
//   secao:secaoConcursoReducer,
//   usuarioLogado:usuarioLogadoReducer,
//   inscricao: inscricaoReducer
// })

// const persistConfig = {
//   key: 'root',
//   storage
// };

// const persistedReducer = persistReducer(persistConfig, reducers);

// export const store = configureStore({
//   reducer: persistedReducer,
// });

export const store = configureStore({
  reducer: {
    concurso: concursoReducer,
    pessoa: pessoaReducer,
    secao:secaoConcursoReducer,
    usuarioLogado:usuarioLogadoReducer,
    inscricao: inscricaoReducer,
    termos: termosReducer
  },
});


export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
