import React from 'react';
import img from '../../../assets/BannerConcursoEditado2.png'
import { CarouselRef } from 'antd/lib/carousel';
import Styles from './banner-component.module.scss';

// interface Banner extends CarouselRef{
//   images: Array<any>,
// }

const Banner: React.FC<any> = (props) =>{

      return (
        <div className={Styles.bannerArea}>
            {/* <div className={Styles.banner}> */}
                <img src={img}></img>
            {/* </div> */}
        </div>
      
      )
}
  
export default Banner;