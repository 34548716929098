import axios from 'axios';
// require('dotenv/config');

var tokenUrl = process.env.REACT_APP_TOKEN_URL;
var clientSecret = process.env.REACT_APP_SECRET;
var clientId = process.env.REACT_APP_CLIENT;
var grant_type = 'client_credentials';
var token;
var ehLocalhost = process.env.REACT_APP_DEVELOPMENT;

const Token = () => {
    return new Promise((resolve, reject) => {        
        setTimeout(() => {
            const apiToken = axios.create({
                baseURL: tokenUrl,                
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
            })            
            apiToken.post('', `grant_type=${grant_type}&client_id=${clientId}&client_secret=${clientSecret}`).then(response => {
                sessionStorage.setItem('Token', response.data.access_token);                
                token = response.data.access_token;
                resolve(token);
            })
        }, 2000);
    })
}
export default Token;