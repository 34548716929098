import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import {ConcursoModel} from '../Models/concurso-model';
import {RootState} from '../store';
import Header from '../../public/components/Header/header-component';
import ConcursoService from '../../services/Concurso-Service/concurso-service';
import { SecaoConcursoModel } from "../Models/secaoconcurso-model";
import SecaoConcursoService from "../../services/Secao-Service/secao-service";

var objetivo= '<spam>O concurso tem por finalidade: </spam> promover a educação fiscal por meio da' +
'conscientização do estudante sobre a função socioeconômica dos tributos,' +
'propiciar o desenvolvimento de uma consciência direcionada ao exercício da cidadania, incentivar o acompanhamento' +
'de aplicação dos recursos públicos,' +
'valorizar novos talentos e incentivar a multidisciplinaridade;'+
'</p>'



interface SecaoConcurso{
    secoes: Array<SecaoConcursoModel>,
    loading: 'idle' | 'pending' | 'succeeded' | 'failed';

}
const initialState:SecaoConcurso  = {
    secoes: [  
    {   
        secaoConcursoID:1,
        titulo:"Objetivos",
        conteudo: "", 
        ordem:1
    },
    {   
        secaoConcursoID:2,
        titulo:"Prêmios",
        conteudo:"",
        ordem:2
    },
    {   
        secaoConcursoID:3,
        titulo:"Datas",
        conteudo:"",
        ordem:3
    },
    {   
        secaoConcursoID:4,
        titulo:"Dúvidas",
        conteudo:"",
        ordem:4
    },
    {   
        secaoConcursoID:5,
        titulo:"Apoiadores",
        conteudo:"",
        ordem:5
    },
    {   
        secaoConcursoID:6,
        titulo:"Videos",
        conteudo:"",
        ordem:6
    },
    {   
        secaoConcursoID:7,
        titulo:"Edital",
        conteudo:"",
        ordem:7
    }
      
    ],
    loading: 'idle'
} ;

export const getSecoes = createAsyncThunk('secoes/getSecoes',async ()=>{
    return SecaoConcursoService.getSecoes();
})


export const secaoConcursoSlice = createSlice({
    name:"secoes",
    initialState,
    reducers:{}, 
    extraReducers:(builder)=>{
        builder.addCase(getSecoes.pending, (state,action)=>{
            state.loading = 'pending'
        })
        builder.addCase(getSecoes.fulfilled, (state, {payload})=>{
            state.secoes = payload.data;
            state.loading = 'succeeded';
        })
        builder.addCase(getSecoes.rejected,(state, action)=>{
            state.loading = 'failed';
        })
    }

});

// export const {carregar, atualizar} = concursoSlice.actions;

export const secaoConcursoState = (state: RootState) => state.secao.secoes;

export default secaoConcursoSlice.reducer;