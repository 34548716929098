import React, { useState, useEffect, useRef } from 'react'
import { Form, Input, Button, Select, message, Tooltip } from 'antd';
import { FormInstance } from 'antd/es/form';
import MaskedInput from 'react-input-mask';
import moment from 'moment';
import { isValidCPF } from '../../../utils/cpf';
import Styles from '../root.module.scss';
import { usuarioLogadoState } from '../../../features/slices/usuario-logado-slice';
import { increment, inscricaoState, incrementPut } from '../../../features/slices/inscricao-slice';
import "./styles.less"
import { useAppSelector, useAppDispatch } from '../../../features/hooks';
import { ehMenorQueIdadeAceita, validarCaracteresEspeciaisENumeroNome } from 'src/utils/validators';


interface FormDados {
    ehEdicao: boolean,
    parentHandle: any
    // dadosInscricao:InscricaoModel[] 
}

const FormDados: React.FC<FormDados> = (props: FormDados) => {
    let formRef = React.createRef<FormInstance>();
    let pessoa = useAppSelector(usuarioLogadoState);
    const [menor, setMenor] = useState<Boolean>(false);
    const [erros, setErros] = useState<any>();
    const dispatch = useAppDispatch();
    const [mask1, setMask1] = useState("(99) 99999-9999");
    const [mask2, setMask2] = useState("(99) 99999-9999");
    let responseSlice = useAppSelector(inscricaoState);
    let inscricao = responseSlice.primeiraInscricao;
    let post = responseSlice.responsePost;
    const [ehEdicao, setEhEdicao] = useState(false);
    const [form] = Form.useForm();
    var concursoID = JSON.parse(sessionStorage.getItem('ConcursoID') || "")

    if (props.ehEdicao) {
        inscricao = responseSlice.inscricao
    }

    useEffect(() => {
        onFill();
    }, []);

    useEffect(() => {
        onFill();
        if (post?.success) {
            setEhEdicao(true)
        }
        if (inscricao?.dataNascimento != "" && inscricao?.dataNascimento)
            validarMenor();
    }, [inscricao, pessoa, post]);

    const unMasked = (input: string) => {
        return input
            .replace(/\D/g, "");
    };

    const onFinishFailed = (data: any) => {
        let res = [];
        res = data.errorFields.map((p: any) => p.name[0]);
        setErros(res);
    }

    const onFinish = (values: any) => {
        setErros([]);
        let user: any = sessionStorage.getItem("usuarioLogado");
        let frag = values.dataNascimento.split("/");
        let data = `${frag[1]}/${frag[0]}/${frag[2]}`;   
        let formatedDate = moment(data).utc().format();             
        values.cpfResponsavel = values.cpfResponsavel == undefined ? null : unMasked(values.cpfResponsavel)
        values.dataNascimento = formatedDate;
        values.aceiteEdital = true;
        dispatch(increment({ "userID": user.id, "concursoID": concursoID, ...values }));
        if (props.ehEdicao) {
            dispatch(incrementPut({ "userID": user.id, "concursoID": concursoID, ...values }));
        }

        form.validateFields()
            .then((r: any) => props.parentHandle("2"))
            .catch((error) => { })

    };
    const onReset = () => {
        formRef.current!.resetFields();
    };

    const onFill = () => {
        //Informações do pessoa Slice      
        formRef.current!.setFieldsValue({
            cpf: pessoa?.cpf,
            email1: pessoa?.email,
            nomeCompleto: pessoa?.nome,
            userID: pessoa?.id,
            dataNascimento: inscricao?.dataNascimento != undefined ? moment(inscricao.dataNascimento).format('DD/MM/YYYY') : null,
            nomeResponsavel: inscricao?.nomeResponsavel,
            rg: inscricao?.rg,
            orgaoEmissor: inscricao?.orgaoEmissor,
            email2: inscricao?.email2,
            cpfResponsavel: inscricao?.cpfResponsavel,
            emailResponsavel: inscricao?.emailResponsavel,
            grauParentescoResponsavel: inscricao?.grauParentescoResponsavel,
            numeroProtocolo: inscricao?.numeroProtocolo,
            telefoneResponsavel: inscricao?.telefoneResponsavel,
        })

    };

    const validarLetrasRG = () => {
        var char = form.getFieldValue("rg") == null ? "" : form.getFieldValue("rg").toString();
        var pattern = '[^0-9]';

        if (!char.match(pattern)) {
            return Promise.resolve();
        }
        else {
            return Promise.reject(new Error('O campo RG deve conter apenas numeros'));
        }

    }

    const validarMenor = () => {
        var hoje = new Date();
        var nascimento = new Date(moment(formRef.current!.getFieldValue("dataNascimento"), 'DD/MM/YYYY').toString());
        let dataValida = moment(formRef.current!.getFieldValue("dataNascimento"), 'DD/MM/YYYY', true).isValid();

        if (dataValida && nascimento < new Date()) {
            ehMenorQueIdadeAceita(nascimento, 18)

            setMenor(ehMenorQueIdadeAceita(nascimento, 18))

            if (ehMenorQueIdadeAceita(nascimento, 13)) {
                message.error('Usuário não pode realizar inscrição. O concurso não permite inscrições para menores de 13 anos')
            }
        }
        else {
            message.error('Data Inválida')
        }
    }

    const validarData = () => {
        let dataValida = moment(formRef.current!.getFieldValue("dataNascimento"), 'DD/MM/YYYY', true).isValid();
        let data = moment(formRef.current!.getFieldValue("dataNascimento"), 'DD/MM/YYYY');
        let yearBirth = data.format('YYYY');
        let currentYear = new Date().getFullYear();
        let yearBirthInt = parseInt(yearBirth);
        let hoje = new Date();
        let nascimento = new Date(moment(formRef.current!.getFieldValue("dataNascimento"), 'DD/MM/YYYY').toString());

        let diff = currentYear - yearBirthInt;

        var m = hoje.getMonth() - nascimento.getMonth();
        //Caso ainda não tenha ultrapassado o dia e o mês
        if (m < 0 || (m === 0 && hoje.getDate() < nascimento.getDate())) {
            diff--;
        }

        if (diff < 13) {
            return Promise.reject(new Error('Usuário não pode realizar inscrição. O concurso não permite inscrições para menores de 13 anos'))
        }
        else if (!dataValida) {
            return Promise.reject(new Error('Data inválida!'));
        }
        return Promise.resolve();
    };

    const validarCpf = () => {
        let cpf = formRef.current!.getFieldValue("cpf").toString();

        let valid = isValidCPF(cpf);

        if (valid)
            return Promise.resolve();
        else
            return Promise.reject(new Error('Cpf inválido!'));
    }

    const validarCpfResponsavel = () => {
        let cpf = formRef.current!.getFieldValue("cpfResponsavel").toString();

        let valid = isValidCPF(cpf);

        if (valid)
            return Promise.resolve();
        else
            return Promise.reject(new Error('Cpf inválido!'));
    }

    const validarEmails = () => {

        var email = pessoa?.email;

        var emailResponsavel = form.getFieldValue("emailResponsavel")

        if (email == emailResponsavel) {
            return Promise.reject(new Error('E-mail do responsável não poder ser o mesmo do participante'))
        }
        return Promise.resolve();
    }

    const textoEmailResponsavel = <p>Ao final da sua inscrição, será enviado um e-mail para seu responsável utilizando o e-mail que foi cadastrado aqui para aprovar sua inscrição nesse concurso.</p>

    return (

        <Form autoComplete="off" ref={formRef} form={form} name="control-ref" className={Styles.formulario} onFinish={onFinish} style={{ padding: '0' }} onFinishFailed={onFinishFailed}>
            <fieldset disabled={props.ehEdicao || ehEdicao}>
                <h1 className={Styles.tituloResponsavel}><b>Dados Participante</b></h1>
                <hr style={{ marginBottom: '10px' }}></hr>
                <Form.Item
                    style={{ marginTop: '-10px' }}
                    name="nomeCompleto"
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    label="Nome Completo"
                    rules={[
                        { type: 'string', min: 6, message: "Nome deve conter no mínimo 6 caracteres" },
                    ]}
                >
                    <Input className={Styles.item} disabled />
                </Form.Item>

                <div className={Styles.groupItem}>
                    <Form.Item
                        style={{ marginTop: '-10px', width: '70%' }}
                        name="rg" label="RG"
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                        rules={[
                            { validator: validarLetrasRG }
                        ]}
                    >
                        <Input className={Styles.item} maxLength={15} />
                    </Form.Item>
                    <Form.Item
                        style={{ marginTop: '-10px', width: '30%' }}
                        name="orgaoEmissor"
                        label="Órg. Emissor"
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                        rules={[
                            { type: 'string' },
                            {
                                validator: async () => {

                                    if (
                                        form.getFieldValue("orgaoEmissor") &&
                                        !validarCaracteresEspeciaisENumeroNome(form.getFieldValue("orgaoEmissor").toString())
                                    ) {
                                        return Promise.reject(new Error('O campo Órg. Emissor deve conter apenas letras'))
                                    }
                                    return Promise.resolve()
                                }
                            }
                        ]}
                    >
                        <Input className={Styles.item} />
                    </Form.Item>
                </div>

                <div className={Styles.groupItem}>

                    <Form.Item
                        style={{ marginTop: '-10px', width: '50%' }}
                        name="cpf"
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                        label="CPF"
                    >
                        {/* <Input className={Styles.item} /> */}
                        <MaskedInput mask="999.999.999-99" className={Styles.inputCpfDisabled} disabled />
                    </Form.Item>

                    <Form.Item
                        style={{ marginTop: '-10px', width: '50%' }}
                        name="dataNascimento"
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                        label="Data Nascimento"
                        // className={Styles.item}                
                        rules={[
                            { required: true, message: "Campo Obrigatório" },
                            { validator: validarData, validateTrigger: 'onSubmit' }
                            // { validator: validarMenor, validateTrigger: 'onSubmit' }
                        ]}>
                        <MaskedInput mask="99/99/9999" className={erros?.find((e: any) => e == 'dataNascimento') ? Styles.itemError : Styles.item} onBlurCapture={() => { validarMenor() }} />
                    </Form.Item >

                </div>

                <Form.Item
                    style={{ marginTop: '-10px' }}
                    name="email1"
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    label="E-mail 1"
                >
                    <Input className={Styles.item} disabled />
                </Form.Item>

                <Form.Item
                    style={{ marginTop: '-10px' }}
                    name="email2"
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    label="E-mail 2"
                >
                    <Input type="email" className={Styles.item} />
                </Form.Item>

                <Form.Item
                    style={{ display: 'none' }}
                    name="userID"

                >
                    <Input style={{ display: 'none' }} />
                </Form.Item>

                <Form.Item className={Styles.dadosResponsavel}
                    noStyle
                >
                    {
                        menor === true ?
                            <div>
                                <h1 className={Styles.tituloResponsavel}><b>Dados Representante Legal</b></h1>
                                <hr style={{ marginBottom: '5%' }}></hr>
                                <div className={Styles.groupItem}>

                                    <Form.Item
                                        style={{ marginTop: '-10px', width: '50%' }}
                                        name="cpfResponsavel"
                                        labelCol={{ span: 24 }}
                                        wrapperCol={{ span: 24 }}
                                        label="CPF Representante Legal"
                                        rules={[
                                            { required: true, message: "Campo obrigatório para menor de idade" },
                                            { validator: validarCpfResponsavel, validateTrigger: 'onSubmit' }
                                        ]}
                                    >
                                        <MaskedInput mask="999.999.999-99" className={erros?.find((e: any) => e == 'cpfResponsavel') ? Styles.itemError : Styles.item} />
                                    </Form.Item>

                                    <Form.Item
                                        style={{ marginTop: '-10px', width: '50%' }}
                                        name="grauParentescoResponsavel"
                                        labelCol={{ span: 24 }}
                                        wrapperCol={{ span: 24 }}
                                        label="Grau de Parentesco"
                                        rules={[
                                            { required: true, message: "Campo obrigatório para menor de idade" },
                                            { type: 'string', message: "Texto Inválido" }
                                        ]}
                                    >
                                        <Input className={Styles.item} />
                                    </Form.Item>
                                </div>
                                <Form.Item
                                    style={{ marginTop: '-10px' }}
                                    name="nomeResponsavel"
                                    labelCol={{ span: 24 }}
                                    wrapperCol={{ span: 24 }}
                                    label="Nome do Representante Legal"
                                    rules={[
                                        { required: true, message: "Campo obrigatório para menor de idade" },
                                        { type: 'string', message: "Texto Inválido" }
                                    ]}
                                >
                                    <Input className={Styles.item} />
                                </Form.Item>

                                <Form.Item
                                    style={{ marginTop: '-10px', width: '100%' }}
                                    name="telefoneResponsavel"
                                    labelCol={{ span: 24 }}
                                    wrapperCol={{ span: 24 }}
                                    label="Telefone do Representante Legal"
                                    rules={[
                                        { required: true, message: "Campo obrigatório para menor de idade" },
                                        { type: 'string', message: "Texto Inválido" }
                                    ]}

                                >
                                    <MaskedInput className={Styles.item} alwaysShowMask={false} mask={mask2}
                                        onBlur={e => {
                                            if (e.target.value.replace("_", "").length === 14) {
                                                setMask2("(99) 9999-9999");
                                            }
                                        }}
                                        onFocus={e => {
                                            if (e.target.value.replace("_", "").length === 14) {
                                                setMask2("(99) 99999-9999");
                                            }
                                        }} />

                                </Form.Item>
                                <Tooltip trigger="click" overlay={textoEmailResponsavel} overlayStyle={{maxWidth:'300px', height:'auto'}} placement="bottomLeft" >
                                    <Form.Item
                                        style={{ marginTop: '-10px' }}
                                        name="emailResponsavel"
                                        labelCol={{ span: 24 }}
                                        wrapperCol={{ span: 24 }}
                                        label="Email do Representante Legal"
                                        rules={[
                                            { required: true, message: "Campo obrigatório para menor de idade" },
                                            { type: 'string', message: "Texto Inválido" },
                                            { validator: validarEmails}
                                        ]}
                                    >
                                        <Input type="email" className={Styles.item} />
                                    </Form.Item>
                                </Tooltip>
                        </div>
                        : null
                }
            </Form.Item>


            </fieldset>
            <div className={Styles.buttonsAreaDados}>
                <Button type="primary" className={Styles.proximo} htmlType="submit">
                    Próximo
                </Button>
            </div>
        </Form >


    )
}

export default FormDados;
