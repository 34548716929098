let caracteresEpeciaisArray = [
 {' ': '%20'},
 {'=': '%3D'},
 {'%': '%25'},
 {'#': '%23'},
 {'$': '%24'},
 {'&': '%26'},
 {':': '%3A'},
 {'<': '%3C'},
 {'>': '%3E'},
 {'[': '%5B'},
 {']': '%5D'},
 {'{': '%7B'},
 {'}': '%7D'},
 {'+': '%2B'},
 {'?': '%3F'}
]

let caracteresEpeciais:any = {
    ' ': '%20',
    '=': '%3D',
    '%': '%25',
    '#': '%23',
    '$': '%24',
    '&': '%26',
    ':': '%3A',
    '<': '%3C',
    '>': '%3E',
    '[': '%5B',
    ']': '%5D',
    '{': '%7B',
    '}': '%7D',
    '+': '%2B',
    '?': '%3F',
}
//link com mais caracteres especiais e seus códigos:
//https://help.smartsheet.com/pt/articles/2478871-url-query-string-form-default-values


const CodificarCaracteresEspeciaisQueryStrings =  {

exec :  (queryString:string) => {       
},
execByCaracter: (caracter: string) => {
    return caracteresEpeciais[caracter]
}
}


export default CodificarCaracteresEspeciaisQueryStrings;