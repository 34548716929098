import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import {UsuarioLogadoModel} from '../Models/usuario-logado-model';
import {RootState} from '../store';
import UserService from "../../services/User-Service/user-service";


interface UsuarioLogado{
    usuarioLogado: UsuarioLogadoModel | null,
    loading: 'idle' | 'pending' | 'succeeded' | 'failed';
}

const initialState: UsuarioLogado  = {
    usuarioLogado: null,
    loading: 'idle'
};

export const getUsuarioLogado = createAsyncThunk('usuarioLogado/getUsuarioLogado',async ()=>{
    return await UserService.getUsuarioLogado();
})


export const usuarioLogadoSlice = createSlice({
    name:"usuarioLogado",
    initialState,
    reducers:{}, 
    extraReducers:(builder)=>{
        builder.addCase(getUsuarioLogado.pending, (state,action)=>{
            state.loading = 'pending'
        })
        builder.addCase(getUsuarioLogado.fulfilled, (state, {payload})=>{            
            state.usuarioLogado = payload;
            state.loading = 'succeeded';
        })
        builder.addCase(getUsuarioLogado.rejected,(state, action)=>{
            state.loading = 'failed';
        })
    }

});

export const usuarioLogadoState = (state: RootState) => state.usuarioLogado.usuarioLogado;

export default usuarioLogadoSlice.reducer;