import React, { useState } from 'react'
import Styles from './spinner-component.module.scss'

interface SpinnerProps {
loading: boolean;
}

const Spinner: React.FC<SpinnerProps> = (props) => {  
  return (
    <>
      {props.loading ?
      <div className={Styles.viewPortOverlay}>
        <div className={Styles.spinner} >
        </div> </div> : null
      }
    </>
  )
}

export default Spinner;
