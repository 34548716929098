import Api from '../index'

const SecaoConcursoService =  {

getSecoes : async ():Promise<any> => {    
    return await Api.get(`/concurso`).then((res:any)=>  {return res.data});
}
}


export default SecaoConcursoService;