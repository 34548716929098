import { StylesProvider } from '@material-ui/styles';
import React from 'react';
import { store } from '../../../features/store';
import TabContent from '../../components/TabContent/tabcontent-component';
import Styles from './inscricao-page.module.scss';


const InscricaoPage: React.FC<any> = () =>{

    return (
        <div className={Styles.inscricaoPage}>
            <TabContent />
        </div>
    )
}

export default InscricaoPage;
