import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import {ConcursoModel} from '../Models/concurso-model';
import {RootState} from '../store';
import ConcursoService from '../../services/Concurso-Service/concurso-service';



interface Concurso{
    concursos: Array<ConcursoModel>,
    loading: 'idle' | 'pending' | 'succeeded' | 'failed';

}
const initialState  = {
    concursos: [  
      
    ],
    loading: 'idle'
} as Concurso;

export const getConcursos = createAsyncThunk('concursos/getConcursos',async ()=>{
    return ConcursoService.getConcursos();
})


export const concursoSlice = createSlice({
    name:"concursos",
    initialState,
    reducers:{}, 
    extraReducers:(builder)=>{
        builder.addCase(getConcursos.pending, (state,action)=>{
            state.loading = 'pending'
        })
        builder.addCase(getConcursos.fulfilled, (state, {payload})=>{
            state.concursos = payload.data;
            state.loading = 'succeeded';
        })
        builder.addCase(getConcursos.rejected,(state, action)=>{
            state.loading = 'failed';
        })
    }

});

// export const {carregar, atualizar} = concursoSlice.actions;

export const concursoState = (state: RootState) => state.concurso;

export default concursoSlice.reducer;