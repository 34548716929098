import React from 'react'
import Style from './cardSecure.module.scss'

interface cardSecure{
    titulo?:string
    cardArquivoUrl?:string
}

const cardSecure:React.FC<cardSecure> = (props) => {
  return (
            <>
                <div className={Style.card}>
                    <button className={Style.btn} >
                        <img className={Style.img} src={props.cardArquivoUrl}></img>
                    </button>
                    <div className={Style.bottom}>
                        <span className={Style.txt}>{props.titulo}</span>           
                    </div>
                </div>
            </>
  )
}
                        

export default cardSecure;
