import Api from '../index'

interface IRegisterModel {
    CPF: string,
    Nome: string,
    Email: string,
    Password: string,
    ConfirmPassword: string
}

interface ILoginModel {
    email: string,
    password: string
}

const UserService = {

    registrar: async (registerModel: IRegisterModel): Promise<any> => {
        return await Api.post(`user/registrar`, registerModel)
    },

    alterarCadastro: async (data:any): Promise<any> =>{
        return await Api.put(`user`, data);
    },

    login: async (loginModel: ILoginModel): Promise<any> => {
        return await Api.post(`user/login?Email=${loginModel.email}&Password=${loginModel.password}`)
    },

    getUsuarioLogado: async () => {
        return await JSON.parse(sessionStorage.getItem('usuarioLogado') || "{}");
    },

    putConfirmEmail: async (cpfCryptografado: any, cpf: any): Promise<any> => {
        return await Api.put(`user/putConfirmEmail?cpfCryptografado=${cpfCryptografado}&cpf=${cpf}`).then((res) =>(res))
    },
    estaLogado: (): boolean => {
        let user = sessionStorage.getItem('usuarioLogado');
        if (user == null || user == 'undefined') {
            return false
        }
        return true
    },
    getByCpfEmail: async(user:any): Promise<any> =>{
        return await Api.get(`user/getByCpfEmail?cpf=${user.cpf}&email=${user.email}`)
    },

    getByID: async(user:any): Promise<any> =>{
        return await Api.get(`user/getbyid?userID=${user.userID}`)
    },

    getByCpfSenha: async(user:any): Promise<any> =>{
        return await Api.get(`user/getByCpfSenha?cpf=${user.cpf}&senha=${user.senha}`)
    },

    esqueciSenha: async(user:any): Promise<any> =>{
        return await Api.get(`user/esqueciSenha?cpf=${user.cpf}&email=${user.email}`)
    },

    alterarSenha: async(user:any): Promise<any> =>{
        return await Api.post(`user/alterarSenha?cpf=${user.cpf}&senha=${user.senha}&senhaConfirmada=${user.confirmarSenha}`)
    },

    aceitarEditalTermo: async(user:any): Promise<any> =>{
        return await Api.post(`user/aceitareditaltermo?userID=${user.userID}&aceiteEdital=${user.aceiteEdital}&aceiteTermo=${user.aceiteTermo}`)
    }
}

export default UserService;