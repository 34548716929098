import React, { useState, useEffect } from 'react'
import Styles from './tabcontent-component.module.scss'
import { message, Tabs } from 'antd';
import 'antd/lib/tabs/style/css';
import { useAppDispatch, useAppSelector } from '../../../features/hooks';
import { usuarioLogadoState } from '../../../features/slices/usuario-logado-slice';
import FormDados from '../FormDados/formDados-component';
import FormContato from '../FormContato/formContato-component';
import FormCategoria from '../FormCategoria/formCategoria-component';
import FormUpload from '../FormUpload/FormUpload-component';
import { getByInscricaoID, inscricaoState, decrement } from '../../../features/slices/inscricao-slice';
import { InscricaoModel } from '../../../features/Models/inscricao-model';
import { store } from '../../../features/store';
import { useHistory, useParams } from 'react-router-dom';
import './tabcontent.module.less'
import Spinner from '../../../public/components/Spinner/spinner-component';
import InscricaoService from 'src/services/Inscricao-Service/inscricao-service';

interface tabContent {

}

const TabContent: React.FC<tabContent> = () => {


    const [menor, setMenor] = useState<Boolean>(false);
    const [tab, setTab] = useState<string>("1");
    const [loading, setLoading] = useState<boolean>(false)
    let responseSlice = useAppSelector(inscricaoState);
    const [inscricaoData, setInscricaoData] = useState<InscricaoModel[]>();
    const dispatch = useAppDispatch();
    let { id } = useParams<any>();
    let ehEdicao = id ? true : false
    let history = useHistory();
    let pessoa = useAppSelector(usuarioLogadoState);

    useEffect(() => {
        
        if (ehEdicao) {
            InscricaoService.inscricaoFinazalida(id).then((res)=> {
                if(res === true) {
                    message.error("Inscrição já foi finalizada")
                    history.push('/error', 'Inscrição já foi finalizada')
                }
            })
            store.dispatch(getByInscricaoID({ inscricaoID: id }));
        }
    }, []);

    useEffect(() => {
        setLoading(responseSlice?.loading == 'pending' ? true : false)     
    }, [responseSlice]);

    useEffect(() => {
        return () => {            
            dispatch(decrement());
        }
    }, [])

    const { TabPane } = Tabs;
    const handleTab = (childData: string) => {
        setTab(childData);
        return tab;
    }
    
    return (
        <>
            <Spinner loading={loading} />
            <div className={Styles.content}>
                <div className={Styles.tab} >
                    <Tabs activeKey={tab} centered size="large" tabBarStyle={{ borderBottom: 'none' }}  className={Styles.TabHeader}>
                        <TabPane tab="Dados" key="1" >
                            <div className={Styles.content}>
                                <FormDados ehEdicao={ehEdicao} parentHandle={handleTab} />
                            </div>
                        </TabPane>
                        <TabPane tab="Contato" key="2">
                            <div className={Styles.content}>
                                <FormContato ehEdicao={ehEdicao} parentHandle={handleTab} />
                            </div>
                        </TabPane>
                        <TabPane tab="Categoria" key="3">
                            <div className={Styles.content}>
                                <FormCategoria ehEdicao={ehEdicao} inscricaoId={id} parentHandle={handleTab} />
                            </div>
                        </TabPane>
                        <TabPane tab="Concurso" key="4" >
                            <FormUpload ehEdicao={ehEdicao} parentHandle={handleTab} />
                        </TabPane>
                    </Tabs>
                </div>
            </div>
        </>
    )
}

export default TabContent;
function dispatch(arg0: any) {
    throw new Error('Function not implemented.');
}

