export const dominiosComuns = [
    "gmail.com",
    "hotmail.com",
    "outlook.com",
    "yahoo.com",
    "multicoisas.com.br",
    "ufms.br",
    "estudante.ifms.edu.br",
    "acadepol.ms.gov.br",
    "agehab.ms.gov.br",
    "agepan.ms.gov.br",
    "agepen.ms.gov.br",
    "agiosul.ms.gov.br",
    "bancocidadao.ms.gov.br",
    "cbm.ms.gov.br",
    "centrodeconvencoes.ms.gov.br",
    "cetran.ms.gov.br",
    "cge.ms.gov.br",
    "cgp.sejusp.ms.gov.br",
    "ciops.ms.gov.br",
    "ciptran.ms.gov.br",
    "concurso.ms.gov.br",
    "defensoria.ms.def.br",
    "defesacivil.ms.gov.br",
    "escolagov.ms.gov.br",
    "fazenda.ms.gov.br",
    "fertel.ms.gov.br",
    "fundect.ms.gov.br",
    "fundesporte.ms.gov.br",
    "fundtur.ms.gov.br",
    "funsau.ms.gov.br",
    "funtrab.ms.gov.br",
    "hemosul.ms.gov.br",
    "imasul.ms.gov.br",
    "jucems.ms.gov.br",
    "msmineral.ms.gov.br",
    "pc.ms.gov.br",
    "pm.ms.gov.br",
    "pre.ms.gov.br",
    "procon.ms.gov.br",
    "reid.ms.gov.br",
    "sas.sejusp.ms.gov.br",
    "saude.ms.gov.br",
    "secc.ms.gov.br",
    "sed.ms.gov.br",
    "sedhast.ms.gov.br",
    "seinfra.ms.gov.br",
    "sejusp.ms.gov.br",
    "sejuv.ms.gov.br",
    "sgi.ms.gov.br",
    "sigo.ms.gov.br",
    "valerenda.ms.gov.br",
]

