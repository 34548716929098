import React  from 'react'
import Styles from '../Filtro/filtro-component.module.scss'
import { useState } from 'react'
import { ConcursoModel } from '../../../features/Models/concurso-model';
import { getConcursos, concursoState} from '../../../features/slices/concurso-slice';
import {useAppSelector, useAppDispatch} from '../../../features/hooks';


// interface FiltroComponent{
//   concursos: Array<ConcursoModel> | undefined
// }

const Filtro:React.FC<any> = () =>{

  let c = useAppSelector(concursoState);

const [busca, setBusca] = useState('Aberto');

const concursosFiltrados = c.loading == "succeeded" ? c.concursos?.filter((concurso:any) => concurso.situacao.includes(busca)): [];

// (concursosFiltrados)

  return (
    <>
        <div className={Styles.container}>
            <button value="aberto" onClick={(ev:any) => setBusca(ev.target.value)}>Abertos</button>
            <button value="fechado" onClick={(ev:any) => setBusca(ev.target.value)}>Fechado</button>
            <button value="em andamento" onClick={(ev:any) => setBusca(ev.target.value)}>Em andamento</button>
            <button value="apto a participar" onClick={(ev:any) => setBusca(ev.target.value)}>Apto a participar</button>
        </div>
    </>
  )
}
export default Filtro;
