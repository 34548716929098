import 'antd/dist/antd.css'
import React, { useEffect, useState } from 'react';
import Style from './error-page.module.scss';
 

const ErrorPage: React.FC<any> = (prop) => {
    return (
        <>
            <div className={Style.Centro}>
                <div className={Style.Titulo}>
                    Erro
                </div>
                <br />
                <strong>{prop.history.location.state}</strong>
            </div>
        </>
    )
}

export default ErrorPage;
